import { MenuItem, OutlinedInput } from "@mui/material";
import ptBR from "date-fns/locale/pt-BR";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { StyledTextArea } from "../../Input/styles";
import {
  AreaContainer,
  ButtonsContainer,
  CheckBox,
  CheckBoxContainer,
  CheckBoxField,
  CheckBoxGroupContent,
  Checkabel,
  Container,
  ContainerInput,
  DateContainer,
  FieldLabel,
  FormikButton,
  Fragment,
  HelpIcon,
  InputTextCurrency,
  ListWrapper,
  RowLine,
  SelectContainer,
  StyledDateView,
  StyledField,
  StyledInputMask,
  StyledLabelSelect,
  StyledSelect,
  SyledRange,
  TextError,
  TimeContainer,
} from "./styles";

export function DatePicker({ ...props }) {
  const { label, name, labelWeight, labelSize, labelFamily, ...rest } = props;
  return (
    <DateContainer>
      <FieldLabel
        htmlFor={name}
        style={{
          fontWeight: labelWeight,
          fontSize: labelSize,
          fontFamily: labelFamily,
        }}
      >
        {label}
      </FieldLabel>
      <StyledField name={name}>
        {({ form, field }) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <StyledDateView
              id={name}
              dateFormat="dd/MM/yyyy"
              showMonthDropdown={true}
              showYearDropdown={true}
              dropdownMode="select"
              {...field}
              {...rest}
              selected={value}
              onChange={val => setFieldValue(name, val)}
              locale={ptBR}
            />
          );
        }}
      </StyledField>
    </DateContainer>
  );
}

export function InputText({ ...props }) {
  const {
    bgColor,
    color,
    downLabel,
    errors,
    errorStyle,
    label,
    labelFamily,
    labelSize,
    labelWeight,
    name,
    touched,
    upLabel,
    ...rest
  } = props;

  return (
    <AreaContainer>
      <Container>
        {upLabel && (
          <FieldLabel
            color={color}
            htmlFor={name}
            style={{
              fontFamily: labelFamily,
              fontSize: labelSize,
              fontWeight: labelWeight,
            }}
          >
            {label}
          </FieldLabel>
        )}
        <ContainerInput bgColor={bgColor}>
          <FieldLabel
            color={color}
            htmlFor={name}
            style={{
              fontFamily: labelFamily,
              fontSize: labelSize,
              fontWeight: labelWeight,
            }}
          >
            {downLabel ? name : !upLabel && label}
          </FieldLabel>
          <StyledInputMask id={name} name={name} {...rest} />
        </ContainerInput>
      </Container>
      {errors && touched && <TextError style={errorStyle}>{errors}</TextError>}
    </AreaContainer>
  );
}

export function InputCurrency({ ...props }) {
  const {
    bgColor,
    color,
    downLabel,
    errors,
    label,
    labelFamily,
    labelSize,
    labelWeight,
    name,
    touched,
    upLabel,
    ...rest
  } = props;

  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  return (
    <AreaContainer>
      <Container>
        {upLabel && (
          <FieldLabel
            color={color}
            htmlFor={name}
            style={{
              fontFamily: labelFamily,
              fontSize: labelSize,
              fontWeight: labelWeight,
            }}
          >
            {label}
          </FieldLabel>
        )}
        <ContainerInput bgColor={bgColor}>
          <FieldLabel
            color={color}
            htmlFor={name}
            style={{
              fontFamily: labelFamily,
              fontSize: labelSize,
              fontWeight: labelWeight,
            }}
          >
            {downLabel ? name : !upLabel && label}
          </FieldLabel>
          <InputTextCurrency
            currency="BRL"
            config={currencyConfig}
            id={name}
            name={name}
            {...rest}
          />
        </ContainerInput>
      </Container>
      {errors && touched && <TextError>{errors}</TextError>}
    </AreaContainer>
  );
}

export function InputRange({ ...props }) {
  const { label, name, value, max, text, ...rest } = props;

  return (
    <DateContainer>
      <FieldLabel htmlFor={name}>{label}</FieldLabel>

      <SyledRange
        type="range"
        name={name}
        max={max}
        value={value}
        id={name}
        {...rest}
      />
      <RowLine value={value} max={max}>
        <label value={value} max={max} htmlFor={name}>
          {text}
        </label>
      </RowLine>
    </DateContainer>
  );
}

export function TimeInput({ ...props }) {
  const { label, name, ...rest } = props;
  return (
    <TimeContainer>
      <StyledInputMask name={name} id={name} {...rest} />
      <FieldLabel htmlFor={name}>{label}</FieldLabel>
    </TimeContainer>
  );
}

export function InputTextArea({ ...props }) {
  const {
    bgColor,
    color,
    errors,
    label,
    labelFamily,
    labelSize,
    labelWeight,
    name,
    touched,
    ...rest
  } = props;

  return (
    <AreaContainer>
      <FieldLabel
        color={color}
        htmlFor={name}
        style={{
          fontWeight: labelWeight,
          fontSize: labelSize,
          fontFamily: labelFamily,
        }}
      >
        {label}
      </FieldLabel>
      <StyledTextArea bgColor={bgColor} id={name} name={name} {...rest} />
      {errors && touched && <TextError>{errors}</TextError>}
    </AreaContainer>
  );
}

export function SelectInput({ ...props }) {
  const {
    label,
    name,
    options,
    bgColor,
    labelFamily,
    labelSize,
    labelWeight,
    fullWidth,
    styles,
    ...rest
  } = props;

  return (
    <SelectContainer style={styles}>
      <StyledLabelSelect
        style={{
          fontFamily: labelFamily,
          fontSize: labelSize,
          fontWeight: labelWeight,
        }}
        id={name}
      >
        {label}
      </StyledLabelSelect>
      <StyledSelect
        style={{ backgroundColor: bgColor }}
        labelId={name}
        variant="outlined"
        input={<OutlinedInput label={label} />}
        {...rest}
      >
        {options?.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </StyledSelect>
    </SelectContainer>
  );
}

export function CheckBoxUnique({ ...props }) {
  const {
    label,
    name,
    disable,
    disabled,
    labelSize,
    labelWeight,
    labelFamily,
    shadow,
    ...rest
  } = props;
  return (
    <CheckBoxContainer>
      <CheckBoxField name={name} {...rest}>
        {({ field }) => (
          <Fragment>
            <FieldLabel
              style={{
                fontSize: labelSize,
                fontWeight: labelWeight,
                fontFamily: labelFamily,
                textShadow: shadow
                  ? "2px 2px 2px rgba(255, 255, 255, 0.7)"
                  : "none",
              }}
              htmlFor={name}
            >
              <CheckBox
                id={name}
                type="checkbox"
                name={name}
                checked={disable ? false : field.value}
                disabled={disabled}
                {...field}
              />
              {label}
            </FieldLabel>
          </Fragment>
        )}
      </CheckBoxField>
    </CheckBoxContainer>
  );
}

export function CheckBoxGroup({ ...props }) {
  const { label, name, options, disabled, ...rest } = props;
  return (
    <CheckBoxContainer>
      <FieldLabel padding htmlFor={name}>
        {label}
      </FieldLabel>
      <CheckBoxGroupContent>
        <ListWrapper>
          <CheckBoxField name={name} {...rest}>
            {({ field }) =>
              options?.map((option, index) => (
                <Fragment key={option.value}>
                  <CheckBox
                    type="checkbox"
                    id={`checkbox-${index}`}
                    {...field}
                    value={option.value}
                    checked={field?.value?.includes(option.value)}
                    disabled={disabled && !field?.value?.includes(option.value)}
                  />
                  <Checkabel
                    htmlFor={option.value}
                    disabled={disabled && !field?.value?.includes(option.value)}
                  >
                    {option.key}
                    <HelpIcon />
                  </Checkabel>
                </Fragment>
              ))
            }
          </CheckBoxField>
        </ListWrapper>
      </CheckBoxGroupContent>
    </CheckBoxContainer>
  );
}

export function ButtonGroup({ ...props }) {
  const { label, name, onClick, options, ...rest } = props;
  return (
    <CheckBoxContainer>
      <FieldLabel htmlFor={name}>{label}</FieldLabel>
      <ButtonsContainer>
        <CheckBoxField name={name} {...rest}>
          {({ field }) =>
            options?.map(option => (
              <Fragment key={option.value}>
                <FormikButton
                  type="button"
                  id={option.value}
                  {...field}
                  value={option.value}
                  onClick={() => onClick(option.value)}
                  selected={field.value === option.value}
                />
              </Fragment>
            ))
          }
        </CheckBoxField>
      </ButtonsContainer>
    </CheckBoxContainer>
  );
}

export function SelectInputMultLanguage({ ...props }) {
  const {
    label,
    name,
    options,
    bgColor,
    labelFamily,
    labelSize,
    labelWeight,
    fullWidth,
    styles,
    ...rest
  } = props;

  return (
    <SelectContainer style={styles}>
      <StyledLabelSelect
        style={{
          fontFamily: labelFamily,
          fontSize: labelSize,
          fontWeight: labelWeight,
        }}
        id={name}
      >
        {label}
      </StyledLabelSelect>
      <StyledSelect
        style={{ backgroundColor: bgColor }}
        labelId={name}
        variant="outlined"
        input={<OutlinedInput label={label} />}
        {...rest}
      >
        {options?.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </StyledSelect>
    </SelectContainer>
  );
}
