/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { StaticButton } from "../../../components/Button";
import { FooterButtons } from "../../../components/FooterButtons";
import FormikControl from "../../../components/FormikControl";
import { SelectSmall } from "../../../components/SelectList";
import useAuth from "../../../hooks/auth";
import { PtBr } from "../../../services/pt_br";
import { TextError } from "../../Professional/Config/styles";
import {
  CardFormConfig,
  CardFormType,
  CardText,
  CardTextHour,
  Checkbox,
  CheckboxContainer,
  ContainerIcon,
  FormData,
  FormTimesWrapper,
  GridCardContent,
  GridContent,
  Label,
  Main,
  PadlockLock,
  PadlockUnlock,
  ScrollViewContent,
  TitleDay,
} from "./styles";

function generateHours(startHour, duration, breakTime) {
  const hours = [];
  const currentDate = moment().format("YYYY-MM-DD");
  const firstHour = moment(`${currentDate} 08:00`, "YYYY-MM-DD HH:mm");
  const lastHour = moment(`${currentDate} 23:59`, "YYYY-MM-DD HH:mm");
  while (firstHour.isBefore(lastHour)) {
    hours.push(firstHour.format("HH:mm"));
    firstHour.add(30, "minutes");
  }

  if (startHour) {
    const interval = moment(startHour, "HH:mm")
      .add(duration + breakTime, "minutes")
      .format("HH:mm");
    const filterHours = hours.filter(
      hour => !moment(hour, "HH:mm").isBefore(moment(interval, "HH:mm")),
    );
    return filterHours;
  }
  return hours;
}

export const getSortedWorkdays = workdays => {
  return workdays.sort((a, b) => a - b);
};

function FormControl({ ...props }) {
  const {
    config,
    configTimes,
    form,
    handleAppointmentTypes,
    handleContinue,
    handleSelect,
    handleWorkdays,
    optionsEndHours,
    optionsStartHours,
    resetAvailability,
    setTimeSelected,
  } = props;
  const { values, errors, touched, handleChange, handleBlur } = form;

  function CheckBoxGroup({ options }) {
    return options?.map(option => (
      <CheckboxContainer key={option.key}>
        <Checkbox
          id={option.key}
          checked={option.checked}
          onChange={() => option.onChange(option.value)}
        />
        <Label htmlFor={option.key} checked={option.checked}>
          {option.label}
        </Label>
        {option.checked && !["online", "in_person"].includes(option.value) && (
          <FormTimesWrapper>
            <SelectSmall
              id={`option-${option.key}-start`}
              defaultValue={config ? option.startHour : ""}
              name="startHour"
              options={optionsStartHours}
              value={option.startHour}
              onChange={({ target }) => {
                handleSelect(target, option.value);
                setTimeSelected(option.value);
              }}
              label="Inicio"
              placeholder="Selecione"
            />
            <p>ATÉ</p>
            <SelectSmall
              id={`option-${option.key}-end`}
              disabled={!option.startHour}
              label="Término"
              name="endHour"
              value={option.endHour}
              options={optionsEndHours[option.value]}
              onChange={({ target }) => handleSelect(target, option.value)}
              placeholder="Selecione"
            />
          </FormTimesWrapper>
        )}
      </CheckboxContainer>
    ));
  }

  return (
    <FormData>
      <CardFormType>
        <TitleDay style={{ marginBottom: "1rem" }}>
          Tipo de Atendimento
        </TitleDay>
        <p style={{ marginBottom: "1rem" }}>
          Selecione a modalidade de atendimento.
        </p>
        <CheckBoxGroup
          options={["online", "in_person"]?.map(item => ({
            key: item,
            value: item,
            label: item === "online" ? "Online" : "Presencial",
            onChange: handleAppointmentTypes,
            checked: values.appointmentTypes?.includes(item),
          }))}
        />
        <div style={{ marginTop: "1rem" }}>
          <FormikControl
            bgColor="#fff"
            control="input"
            value={values.appointmentDuration}
            name="appointmentDuration"
            label="Duração do atendimento (minutos)"
            upLabel={true}
            type="number"
            min={0}
            max={120}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <TextError>
            {errors.appointmentDuration &&
              touched.appointmentDuration &&
              errors.appointmentDuration}
          </TextError>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <FormikControl
            bgColor="#fff"
            control="input"
            value={values.breakTime}
            name="breakTime"
            label="Tempo de descanso (minutos)"
            upLabel={true}
            type="number"
            min={0}
            max={60}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <TextError>
            {errors.breakTime && touched.breakTime && errors.breakTime}
          </TextError>
        </div>

        <div
          style={{
            marginTop: "3rem",
            display: "flex",
            justifyContent: "center",
            width: "50%",
          }}
        >
          <StaticButton
            type="button"
            height="3rem"
            onClick={resetAvailability}
            title="Limpar Disponibilidade"
          />
        </div>
      </CardFormType>
      <CardFormConfig>
        <TitleDay style={{ marginBottom: "1rem" }}>
          Dias e Horários da Semana
        </TitleDay>
        <p style={{ marginBottom: "1rem" }}>
          Escolha os dias e horários da semana nos quais você ira trabalhar.
        </p>
        <CheckBoxGroup
          options={PtBr.days_name?.map((dayWeek, day) => ({
            key: dayWeek,
            value: String(day),
            label: dayWeek,
            onChange: handleWorkdays,
            checked: values.workdays?.includes(String(day)),
            startHour: configTimes[day]?.startHour,
            endHour: configTimes[day]?.endHour,
          }))}
        />
        <TextError>{errors.workdays}</TextError>
        {config && (
          <StaticButton
            type="button"
            height="3rem"
            onClick={handleContinue}
            title="Salvar"
          />
        )}
      </CardFormConfig>
    </FormData>
  );
}

function CardTime({ ...props }) {
  const { block, time, duration, handleClick } = props;

  return (
    <GridCardContent block={block}>
      <div>
        <CardTextHour weigth={550}>
          {`${time} - ${moment(time, "HH:mm")
            .add(duration, "minutes")
            .format("HH:mm")}`}
        </CardTextHour>
        <CardText>
          {block ? "Horário bloqueado" : "Horário disponível"}
        </CardText>
      </div>

      <ContainerIcon
        type="button"
        onClick={handleClick}
        title={block ? "Desbloquear" : "Bloquear"}
      >
        {block ? <PadlockLock /> : <PadlockUnlock />}
      </ContainerIcon>
    </GridCardContent>
  );
}

export default function Availability({ ...props }) {
  const { config, form, goBack, next } = props;
  const { values, setFieldValue, handleSubmit } = form;
  const { user } = useAuth();
  const [availabilityOptions, setAvailabilityOptions] = useState([]);
  const [configTimes, setConfigTimes] = useState({});
  const [optionsEndHours, setOptionsEndHours] = useState({});
  const optionsStartHours = generateHours([]);
  const [timeSelected, setTimeSelected] = useState(null);

  const getAvailability = (
    appointmentDuration,
    breakTime,
    startHour,
    endHour,
  ) => {
    const momentStartHour = moment(startHour, "HH:mm");
    const momentEndHour = moment(endHour, "HH:mm").subtract(
      appointmentDuration - 1,
      "minutes",
    );
    const duration = (appointmentDuration || 50) + breakTime || 10;
    const availability = [];
    while (momentStartHour.isBefore(momentEndHour)) {
      availability.push(momentStartHour.format("HH:mm"));
      momentStartHour.add(duration, "minutes");
    }
    return availability;
  };

  useEffect(() => {
    const getStartAndEndHour = availabilityTimes => {
      const timeDay = {};
      const duration = user?.availabilityRule?.appointmentDuration;
      const breakTime = user?.availabilityRule?.breakTime;
      const interval = 30;

      PtBr.days_name.forEach((_, day) => {
        const startTime = availabilityTimes[day]?.length
          ? availabilityTimes[day][0]
          : "";

        let lastTime = "";
        let endTime = "";
        if (availabilityTimes[day] && availabilityTimes[day]?.length !== 0) {
          lastTime = moment(
            availabilityTimes[day][availabilityTimes[day]?.length - 1],
            "HH:mm",
          ).add(duration + breakTime, "minutes");

          const prevTime = interval - (lastTime.minute() % interval);
          lastTime.add(prevTime, "minutes");
          endTime =
            lastTime.format("HH:mm") === "00:00"
              ? moment("23:30", "HH:mm")
              : lastTime.format("HH:mm");
        }
        const availability = moment(startTime, "HH:mm").isValid();
        timeDay[day] = {
          startHour: availability ? startTime : "",
          endHour:
            endTime !== "" ? moment(endTime, "HH:mm").format("HH:mm") : "",
        };
      });

      const endHours = Object.keys(timeDay).reduce((acc, day) => {
        if (timeDay[day].startHour !== "" && timeDay[day].endHour !== "") {
          acc[day] = generateHours(timeDay[day].startHour, duration, breakTime);
          return acc;
        }
        return acc;
      }, {});

      setOptionsEndHours(endHours);
      setConfigTimes(timeDay);
    };

    if (user && Object.keys(values.appointmentTimeBlocks)?.length > 0) {
      getStartAndEndHour(values.appointmentTimeBlocks);
    }
  }, [values.workdays]);

  useEffect(() => {
    const setAppointmentTimeBlocks = () => {
      const availability = PtBr.days_name?.map((_, day) => {
        if (
          configTimes[day] &&
          configTimes[day]?.startHour &&
          configTimes[day]?.endHour
        ) {
          return getAvailability(
            values.appointmentDuration,
            values.breakTime,
            configTimes[day].startHour,
            configTimes[day].endHour,
          );
        }
        return [];
      });

      setAvailabilityOptions(availability);
    };
    setAppointmentTimeBlocks();

    timeSelected !== null &&
      setOptionsEndHours({
        ...optionsEndHours,
        [timeSelected]: generateHours(
          configTimes[timeSelected]?.startHour,
          values.appointmentDuration,
          values.breakTime,
        ),
      });
  }, [configTimes, values.appointmentDuration, values.breakTime, timeSelected]);

  const validationTimes = () => {
    const isValid = values.workdays?.map(day => {
      if (
        configTimes[day]?.startHour === "" ||
        configTimes[day]?.endHour === ""
      ) {
        toast.error(
          `Você deve selecionar os horários de início e término ${PtBr.days_name_availability[day]}`,
        );
        return false;
      }
      return true;
    });

    if (
      Object.values(values.appointmentTimeBlocks)?.every(
        item => item?.length === 0,
      )
    ) {
      toast.error("Você deve selecionar pelo menos um horário disponível");
      return false;
    }

    return !isValid?.some(item => item === false);
  };

  const validationAppointmentTypes = () => {
    if (values.appointmentTypes?.length === 0) {
      toast.error("Você deve selecionar pelo menos um tipo de atendimento");
      return false;
    }
    return true;
  };

  const handleContinue = event => {
    event.preventDefault();
    const timeValid = validationTimes();
    const typeValid = validationAppointmentTypes();
    if (timeValid && typeValid) {
      if (config) {
        handleSubmit();
      } else next(values.clinicId);
    }
  };

  const removeblockedTimes = (day, blockedTimes) => {
    const newAppointmentTimeBlocks = values.appointmentTimeBlocks[day]?.filter(
      time => !blockedTimes.includes(time),
    );
    setFieldValue("appointmentTimeBlocks", {
      ...values.appointmentTimeBlocks,
      [day]: newAppointmentTimeBlocks,
    });
  };

  const handleClick = (time, day) => {
    const index = values.blockedTimes[day]?.indexOf(time);
    let updateTime = [];
    if (index > -1) {
      values.blockedTimes[day].splice(index, 1);
      updateTime = values.blockedTimes[day];
    } else updateTime = [...(values.blockedTimes[day] || []), time];
    removeblockedTimes(day, updateTime);
    setFieldValue("blockedTimes", {
      ...values.blockedTimes,
      [day]: updateTime,
    });
  };

  const handleSelect = (target, day) => {
    const { name, value } = target;
    const newConfigTimes = { ...configTimes };
    newConfigTimes[day] = {
      ...newConfigTimes[day],
      [name]: value,
    };
    setConfigTimes(newConfigTimes);
    const appointmentTimeBlocks = values.appointmentTimeBlocks;
    values.workdays.forEach(dayWeek => {
      if (dayWeek === day) {
        appointmentTimeBlocks[dayWeek] = getAvailability(
          values.appointmentDuration,
          values.breakTime,
          newConfigTimes[dayWeek]?.startHour,
          newConfigTimes[dayWeek]?.endHour,
        );
      }
    });
    setFieldValue("appointmentTimeBlocks", {
      ...values.appointmentTimeBlocks,
      [day]: appointmentTimeBlocks[day],
    });
  };

  const resetAvailability = () => {
    setFieldValue("appointmentTimeBlocks", {});
    setFieldValue("blockedTimes", {});
    setFieldValue("workdays", []);
    setFieldValue("appointmentTypes", ["online"]);
    setFieldValue("appointmentDuration", 50);
    setFieldValue("breakTime", 10);
    setConfigTimes({});
  };

  const handleWorkdays = day => {
    let workdays = values?.workdays;
    const index = workdays.indexOf(day);
    if (index > -1) {
      workdays.splice(index, 1);
      setFieldValue("blockedTimes", {
        ...values.blockedTimes,
        [day]: [],
      });
      setFieldValue("appointmentTimeBlocks", {
        ...values.appointmentTimeBlocks,
        [day]: [],
      });
      getSortedWorkdays(workdays);
      setFieldValue("workdays", workdays);
    } else {
      workdays = [...workdays, day];
      getSortedWorkdays(workdays);
      setFieldValue("workdays", workdays);
    }
  };

  const handleAppointmentTypes = type => {
    let appointmentTypes = values?.appointmentTypes;

    const index = appointmentTypes.indexOf(type);
    if (index > -1) {
      appointmentTypes.splice(index, 1);
      setFieldValue("appointmentTypes", appointmentTypes);
    } else {
      appointmentTypes = [...appointmentTypes, type];
      setFieldValue("appointmentTypes", appointmentTypes);
    }
  };

  return (
    <Main>
      <FormControl
        config={config}
        configTimes={configTimes}
        form={form}
        handleAppointmentTypes={handleAppointmentTypes}
        handleContinue={handleContinue}
        handleSelect={handleSelect}
        handleWorkdays={handleWorkdays}
        optionsEndHours={optionsEndHours}
        optionsStartHours={optionsStartHours}
        timeSelected={timeSelected}
        setTimeSelected={setTimeSelected}
        resetAvailability={resetAvailability}
      />
      <GridContent>
        {values.workdays?.map(day => {
          return (
            <div key={day}>
              <TitleDay>{PtBr.days_name[day]}</TitleDay>
              <ScrollViewContent key={day}>
                {availabilityOptions[day]?.map(time => (
                  <CardTime
                    key={time}
                    block={values.blockedTimes[day]?.includes(time)}
                    time={time}
                    duration={values.appointmentDuration}
                    handleClick={() => handleClick(time, day)}
                  />
                ))}
              </ScrollViewContent>
            </div>
          );
        })}
      </GridContent>
      {!config && (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <FooterButtons goBack={goBack} next={handleContinue} maxWidth={500} />
        </div>
      )}
    </Main>
  );
}
