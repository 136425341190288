/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import LoaderSpinner from "../components/LoaderSpinner";
import LogoClinicDefault from "../images/logo-default-clinic.png";
import api from "../services/api";
import getProfessionName from "../utils/getProfessionName";
export const AuthContext = createContext();

// Checking if the user is sending a new base64 or it was just the amazon url.
const isBase64 = avatar => {
  const arr = avatar.split("data:image/");
  return arr?.length > 1 ? true : false;
};

const isValidImage = image => {
  if (image) {
    if (isBase64(image)) {
      return { data: image };
    }
    return undefined;
  }
  return undefined;
};

function convertImportedImageToBase64(imagePath, callback) {
  fetch(imagePath)
    .then(response => response.blob())
    .then(blob => {
      const reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.onerror = function (error) {
        console.error("Error converting imported image to Base64:", error);
      };
      reader.readAsDataURL(blob);
    })
    .catch(error => console.error("Error fetching the image:", error));
}

export function AuthProvider({ children }) {
  const [authData, setAuthData] = useState({});
  const [publicProfile, setPublicProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [LogoClinicDefaultBase64, setLogoClinicDefaultBase64] = useState("");

  convertImportedImageToBase64(LogoClinicDefault, function (base64Image) {
    setLogoClinicDefaultBase64(base64Image);
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userStorage = localStorage.getItem("user");
    const publicUserStorage = localStorage.getItem("publicUser");
    if (publicUserStorage) {
      setPublicProfile(JSON.parse(publicUserStorage));
    }

    if (userStorage) {
      setAuthData(JSON.parse(userStorage));
    }
    if (token) {
      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    setLoading(false);
  }, []);

  function setGlobalUser(data) {
    const authToken = localStorage.getItem("token");
    const user = {
      ...data.attributes,
      id: data.id,
      type: data.type,
      firstName: data.attributes.name || data.attributes.firstName,
    };
    localStorage.setItem("type", user.type);
    localStorage.setItem("token", authToken || user.authToken);
    localStorage.setItem("user", JSON.stringify(user));
    setAuthData(user);
  }

  function validateInfo(type) {
    switch (type) {
      case "pending":
        return toast.warning(
          `Atenção! Sua conta esta em fase de aprovação e não aparecerá
          na busca de profissionais.`,
          { autoClose: false },
        );
      case "incomplete":
        return toast.warning(
          `Atenção! Seu cadastro está incompleto, e não aparecerá
          na busca de profissionais. Por favor clique em configurações e complete
          seu perfil para aproveitar as funcionalidades da Allminds!`,
          { autoClose: false },
        );
      case "incompleteBank":
        return toast.warning(
          `Sua conta bancária está incompleta, por favor atualize suas informações
          bancárias para aproveitar as funcionalidades da Allminds!`,
          { autoClose: false },
        );
      default:
        return;
    }
  }

  async function signIn({ email, password, type }) {
    try {
      const { data } = await api.post(`/authenticate-${type}`, {
        email: email.toLowerCase(),
        password: password,
      });
      const user = { ...data.data.attributes, id: data.data.id, type: type };
      if (user.authToken) {
        // Setting the token for the api requests
        api.defaults.headers.Authorization = `Bearer ${user.authToken}`;
        setGlobalUser(data.data);
        if (user?.type === "professional") {
          if (user?.status !== "approved") {
            validateInfo("pending");
          }
          if (!user?.clinic?.id && user?.professionalIncomplete) {
            validateInfo("incomplete");
          }
          if (!user?.clinic?.id && user?.paymentAccount?.id === null) {
            validateInfo("incompleteBank");
          }
        }
      } else {
        throw new Error("Token not found");
      }
    } catch (error) {
      if (error.response) {
        if (
          error.response.data.error.user_authentication ===
          "User approval pending"
        ) {
          toast.error("Usuário ainda está pendente de aprovação.");
        } else if (
          error.response.data.error.user_authentication ===
          "invalid credentials"
        ) {
          toast.error("Credenciais inválidas");
        } else {
          toast.error("Um erro inesperado ocorreu");
        }
      } else {
        toast.error("Um erro inesperado ocorreu");
      }
    }
  }

  async function signOut() {
    api.defaults.headers.Authorization = null;

    setAuthData("");
    localStorage.clear();
  }

  async function signUpProfessional(formValues) {
    try {
      const request = {
        data: {
          type: "professional",
          attributes: {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            socialName: formValues.socialName,
            avatar: formValues.avatar ? { data: formValues.avatar } : null,
            email: formValues.email,
            documentNumber: formValues.documentNumber,
            password: formValues.password,
            birthday: formValues.birthday,
            phone: formValues.phone,
            cancelPolicy: "Não tem",
            addressAttributes: {
              cep: formValues.cep,
              street: formValues.street,
              neighborhood: formValues.neighborhood,
              country: formValues.country,
              complement: formValues.complement,
              street_number: formValues.street_number,
              city: formValues.city,
              state: formValues.state,
            },
            availabilityRuleAttributes: {
              appointmentDuration: formValues.appointmentDuration,
              appointmentTimeBlocks: formValues.appointmentTimeBlocks,
              blockedTimes: formValues.blockedTimes,
              appointmentTypes: formValues.appointmentTypes,
              breakTime: formValues.breakTime,
              workdays: formValues.workdays,
            },
            profession: getProfessionName(formValues.profession, "toEng"),
            professionalDocumentNumber: formValues.job_register,
            professionalDocumentFile: formValues.certificate
              ? { data: formValues.certificate }
              : null,
            professionalEducationDocumentFile: formValues.educationCertificate
              ? { data: formValues.educationCertificate }
              : null,
            academicBackground: formValues.formations,
            specialities: formValues.specialities,
            reasons: formValues.reasons,
            approaches: formValues.approaches,
            bio: formValues.bio,
            termId: formValues.termId,
            ageGroups: formValues.age_group,
            yearsOfExperience: formValues.experience_time,
            presentationVideo: formValues.presentationVideo,
            sessionPrice: formValues.sessionPrice,
            preferentialPayment: formValues.preferentialPayment,
            paymentAccountAttributes: {
              documentNumber: formValues.documentNumber,
              name: formValues.name,
              bankAg: formValues.bankAg,
              accountType: formValues.accountType,
              bankCc: formValues.bankCc,
              bankCode: formValues.bankCode,
              agenciaDv: formValues.agenciaDv,
              contaDv: formValues.contaDv,
            },
            subscriptionAttributes: {
              planName: formValues.planName,
              creditCardAttributes: {
                cardNumber: formValues.cardNumber,
                cardExpiration_date: formValues.cardExpiration_date,
                cardHolderName: formValues.cardHolderName,
                cardCvv: formValues.cardCvv,
                default: formValues.setDefault,
              },
            },
            clinicId: formValues.clinicId || null,
            acceptPix: formValues.acceptPix,
            acceptCredit: formValues.acceptCredit,
          },
        },
      };
      if (!formValues.clinicId) {
        delete request.data.attributes.clinicId;
        delete request.data.attributes.acceptPix;
        delete request.data.attributes.acceptPix;
      }

      if (formValues.planName === "free") {
        delete request.data.attributes.subscriptionAttributes
          .creditCardAttributes;
      }

      if (formValues.clinicId) {
        delete request.data.attributes.subscriptionAttributes;
      }

      const { data } = await api.post("/professionals/sign_up", request);
      if (data.data) {
        const user = {
          ...data.data.attributes,
          id: data.data.id,
          type: data.data.type,
        };
        setPublicProfile(user);
        return true;
      } else {
        throw new Error(JSON.stringify(data.error));
      }
    } catch (error) {
      if (error.response) {
        error.response.data.errors.forEach(er => {
          if (er.title === "Email já está em uso") {
            toast.error(er.title);
          }
        });
      }

      return false;
    }
  }

  async function updateProfessional(formValues, professionalId) {
    try {
      const request = {
        data: {
          type: "professional",
          attributes: {
            avatar: isValidImage(formValues.avatar),
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            socialName: formValues.socialName,
            phone: formValues.phone,
            birthday: formValues.birthday,
            documentNumber: formValues.documentNumber,
            addressAttributes: {
              id: authData.address.id || null,
              cep: formValues.cep,
              street: formValues.street,
              neighborhood: formValues.neighborhood,
              country: formValues.country,
              city: formValues.city,
              complement: formValues.complement,
              street_number: formValues.street_number,
              state: formValues.state,
            },
            professionalDocumentNumber: formValues.professionalDocumentNumber,
            academicBackground: formValues.academicBackground,
            specialities: formValues.specialities,
            reasons: formValues.reasons,
            approaches: formValues.approaches,
            bio: formValues.bio,
            ageGroups: formValues.ageGroups,
            yearsOfExperience: formValues.yearsOfExperience,
            presentationVideo: formValues.presentationVideo,
            sessionPrice: formValues.sessionPrice,
            preferentialPayment: formValues.preferentialPayment,
            availabilityRuleAttributes: {
              ...authData.availabilityRule,
              appointmentDuration: formValues.appointmentDuration,
              appointmentTimeBlocks: formValues.appointmentTimeBlocks,
              blockedTimes: formValues.blockedTimes,
              appointmentTypes: formValues.appointmentTypes,
              workdays: formValues.workdays,
              breakTime: formValues.breakTime,
            },
            welcome: formValues.welcome,
            termId: formValues.termId || authData?.term?.id,
            clinicId: formValues.clinicId || null,
            acceptPix: formValues.acceptPix,
            acceptCredit: formValues.acceptCredit,
          },
        },
      };
      if (!formValues.clinicId) {
        delete request.data.attributes.clinicId;
        delete request.data.attributes.acceptPix;
        delete request.data.attributes.acceptPix;
      }
      if (authData?.documentNumber) {
        delete request.data.attributes.documentNumber;
      }
      if (!authData?.address?.id) {
        delete request.data.attributes.addressAttributes;
      }
      const { data } = await api.put(
        `/professionals/${professionalId}`,
        request,
      );
      if (data.data) {
        setGlobalUser(data.data);
      } else {
        throw new Error(JSON.stringify(data.error));
      }

      return true;
    } catch (error) {
      if (error.response) {
        error.response.data.errors.forEach(er => {
          if (er.title === "Email já está em uso") {
            toast.error(er.title);
            return false;
          }
          if (er.title === "Document number já está em uso") {
            toast.error("Este CPF já está em uso! Forneça outro CPF.");
            return false;
          }
        });
      } else {
        toast.error("Não foi possível completar seu registro");
        return false;
      }
    }
  }

  async function professionalUpdateBasicData(formValues, professionalId) {
    try {
      const request = {
        data: {
          type: "professional",
          attributes: {
            avatar: isValidImage(formValues.avatar),
            documentNumber: formValues.documentNumber,
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            socialName: formValues.socialName,
            phone: formValues.phone,
            birthday: formValues.birthday,
            termId: authData?.term?.id,
          },
        },
      };
      const { data } = await api.put(
        `/professionals/${professionalId}`,
        request,
      );
      if (data.data) {
        setGlobalUser(data.data);
      } else {
        throw new Error(JSON.stringify(data.error));
      }
      return true;
    } catch (error) {
      if (error.response) {
        error.response.data.errors.forEach(er => {
          if (er.title === "Email já está em uso") {
            toast.error(er.title);
            return false;
          }
          if (er.title === "Document number já está em uso") {
            toast.error("Este CPF já está em uso! Forneça outro CPF.");
            return false;
          }
        });
      } else {
        toast.error("Não foi possível completar seu registro");
        return false;
      }
    }
  }

  async function professionalUpdateAdress(formValues, professionalId) {
    try {
      const request = {
        data: {
          type: "professional",
          attributes: {
            addressAttributes: {
              id: authData.address.id || null,
              cep: formValues.cep,
              street: formValues.street,
              neighborhood: formValues.neighborhood,
              country: formValues.country,
              city: formValues.city,
              complement: formValues.complement,
              street_number: formValues.street_number,
              state: formValues.state,
            },
            termId: authData?.term?.id,
          },
        },
      };
      if (!authData?.address?.id) {
        delete request.data.attributes.addressAttributes.id;
      }
      const { data } = await api.put(
        `/professionals/${professionalId}`,
        request,
      );
      if (data.data) {
        setGlobalUser(data.data);
      } else {
        throw new Error(JSON.stringify(data.error));
      }

      return true;
    } catch (error) {
      if (error.response) {
        error.response.data.errors.forEach(er => {
          if (er.title === "Email já está em uso") {
            toast.error(er.title);
            return false;
          }
          if (er.title === "Document number já está em uso") {
            toast.error("Este CPF já está em uso! Forneça outro CPF.");
            return false;
          }
        });
      } else {
        toast.error("Não foi possível completar seu registro");
        return false;
      }
    }
  }

  async function professionalUpdateProfessionalInfos(
    formValues,
    professionalId,
  ) {
    try {
      const request = {
        data: {
          type: "professional",
          attributes: {
            professionalDocumentNumber: formValues.professionalDocumentNumber,
            academicBackground: formValues.academicBackground,
            specialities: formValues.specialities,
            reasons: formValues.reasons,
            approaches: formValues.approaches,
            bio: formValues.bio,
            ageGroups: formValues.ageGroups,
            yearsOfExperience: formValues.yearsOfExperience,
            presentationVideo: formValues.presentationVideo,
            sessionPrice: formValues.sessionPrice,
            preferentialPayment: formValues.preferentialPayment,
            availabilityRuleAttributes: {
              ...authData.availabilityRule,
              appointmentDuration: formValues.appointmentDuration,
              appointmentTimeBlocks: formValues.appointmentTimeBlocks,
              blockedTimes: formValues.blockedTimes,
              appointmentTypes: formValues.appointmentTypes,
              workdays: formValues.workdays,
              breakTime: formValues.breakTime,
            },
            termId: authData?.term?.id,
            ownMeetingLink: formValues.newPreferOwnMeetingLink
              ? formValues.ownMeetingLink
              : null,
            preferOwnMeetingLink: formValues.newPreferOwnMeetingLink,
          },
        },
      };
      const { data } = await api.put(
        `/professionals/${professionalId}`,
        request,
      );

      if (data.data) {
        setGlobalUser(data.data);
      } else {
        throw new Error(JSON.stringify(data.error));
      }

      return true;
    } catch (error) {
      if (error.response) {
        error.response.data.errors.forEach(er => {
          if (er.title === "Email já está em uso") {
            toast.error(er.title);
            return false;
          }
          if (er.title === "Document number já está em uso") {
            toast.error("Este CPF já está em uso! Forneça outro CPF.");
            return false;
          }
        });
      } else {
        toast.error("Não foi possível completar seu registro");
        return false;
      }
    }
  }

  async function deleteProfessional(id) {
    try {
      setLoading(true);
      await api.delete(`/professionals/${id}`);
      toast.success(
        `Sua conta foi excluída com sucesso. Se quiser criar uma nova conta, sinta-se
        à vontade. Para reativar sua conta, entre em contato com nossa equipe.
        Agradecemos pela compreensão. Atenciosamente, Allminds.`,
        { autoClose: false },
      );
    } catch (error) {
      toast.error(
        "Não foi possível excluir sua conta! Entre em contato com a nossa equipe.",
        {
          autoClose: false,
        },
      );
    } finally {
      setLoading(false);
      signOut();
    }
  }

  async function signUpClient(formValues) {
    try {
      const request = {
        data: {
          type: "client",
          attributes: {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            email: formValues.email,
            phone: formValues.phone,
            password: formValues.password,
            password_confirmation: formValues.password_confirmation,
            schoolId: formValues.schoolId || null,
            schoolType: formValues.schoolType || null,
          },
        },
      };
      const { data } = await api.post("clients/sign_up", request);
      if (data.data) {
        setGlobalUser(data.data);
        return true;
      } else {
        throw new Error(JSON.stringify(data.error));
      }
    } catch (error) {
      toast.error("Não foi possível completar seu registro");

      if (error.response) {
        error.response.data.errors.forEach(er => {
          if (er.title === "Email já está em uso") {
            toast.error(er.title);
          }
        });
      }

      return false;
    }
  }

  async function updateClient(formValues, id) {
    try {
      const request = {
        data: {
          type: "client",
          attributes: {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            email: formValues.email,
            avatar: isValidImage(formValues.avatar),
            birthday: formValues.birthday,
            phone: formValues.phone,
            documentNumber: formValues.documentNumber,
            password: formValues.password,
            password_confirmation: formValues.password_confirmation,
            needLegalGuardian: formValues.needLegalGuardian,
            legalGuardianFullName: formValues.legalGuardianFullName,
            legalGuardianEmail: formValues.legalGuardianEmail,
            legalGuardianDocumentNumber: formValues.legalGuardianDocumentNumber,
            legalGuardianBirthday: formValues.legalGuardianBirthday,
            legalGuardianPhone: formValues.legalGuardianPhone,
            addressAttributes: {
              id: authData.address.id,
              cep: formValues.cep,
              street: formValues.street,
              neighborhood: formValues.neighborhood,
              country: formValues.country,
              city: formValues.city,
              state: formValues.state,
              street_number: formValues.street_number,
              complement: formValues.complement,
            },
            schoolId: formValues.schoolId || null,
            welcome: formValues.welcome,
          },
        },
      };
      if (authData.documentNumber === formValues.documentNumber)
        delete request.data.attributes.documentNumber;
      if (authData.address.id === null || !authData.address.id)
        delete request.data.attributes.addressAttributes.id;
      const { data } = await api.put(`/clients/sign_up/${id}`, request);
      if (data.data) {
        setGlobalUser(data.data);
        return true;
      } else {
        throw new Error(JSON.stringify(data.error));
      }
    } catch (error) {
      if (error.response) {
        error.response.data.errors.forEach(er => {
          if (er.title === "Email já está em uso") {
            toast.error(er.title);
            return false;
          }
          if (er.id.includes("document_number")) {
            toast.error("Este CPF já está em uso! Forneça outro CPF.");
            return false;
          }
        });
      } else {
        toast.error("Não foi possível completar seu registro");
        return false;
      }
    }
  }

  async function updateClientOnboarding(formValues, id) {
    try {
      const request = {
        data: {
          type: "client",
          attributes: { welcome: formValues.welcome },
        },
      };
      const { data } = await api.put(`/clients/sign_up/${id}`, request);
      if (data.data) {
        setGlobalUser(data.data);
        return true;
      } else {
        throw new Error(JSON.stringify(data.error));
      }
    } catch (error) {
      if (error.response) {
        error.response.data.errors.forEach(er => {
          if (er.title === "Email já está em uso") {
            toast.error(er.title);
            return false;
          }
          if (er.title === "Document number já está em uso") {
            toast.error("Este CPF já está em uso! Forneça outro CPF.");
            return false;
          }
        });
      } else {
        toast.error("Não foi possível completar seu registro");
        return false;
      }
    }
  }

  async function signUpClinic(formValues) {
    try {
      const request = {
        data: {
          type: "clinic",
          attributes: {
            avatar: formValues.avatar
              ? { data: formValues.avatar }
              : { data: LogoClinicDefaultBase64 },
            banner: formValues.banner ? { data: formValues.banner } : null,
            name: formValues.name,
            description: formValues.description,
            email: formValues.email,
            phone: formValues.phone,
            password: formValues.password,
            password_confirmation: formValues.confirm_password,
            documentNumber: formValues.documentNumber,
            paymentAccountAttributes: {
              documentNumber: formValues.paymentDocumentNumber,
              name: formValues.paymentName,
              bankAg: formValues.bankAg,
              accountType: formValues.accountType,
              bankCc: formValues.bankCc,
              bankCode: formValues.bankCode,
              agenciaDv: formValues.agenciaDv,
              contaDv: formValues.contaDv,
            },
            addressAttributes: {
              cep: formValues.cep,
              street: formValues.street,
              neighborhood: formValues.neighborhood,
              country: formValues.country,
              city: formValues.city,
              state: formValues.state,
              street_number: formValues.street_number,
              complement: formValues.complement,
            },
            subscriptionAttributes: {
              creditCardAttributes: {
                cardNumber: formValues.cardNumber,
                cardExpiration_date: formValues.cardExpiration_date,
                cardHolderName: formValues.cardHolderName,
                cardCvv: formValues.cardCvv,
              },
            },
            responsibleName: formValues.responsibleName,
            responsibleDocumentNumber: formValues.responsibleDocumentNumber,
            responsibleAbout: formValues.responsibleAbout,
            responsibleAvatar: formValues.responsibleAvatar
              ? { data: formValues.responsibleAvatar }
              : null,
            acceptCredit: formValues.acceptCredit,
            acceptPix: formValues.acceptPix,
            postPaid: formValues.postPaid,
            prePaid: formValues.prePaid,
            postPaidDay: formValues.postPaidDay,
            externalPayment: formValues.externalPayment,
            allmindsBenefit: false,
          },
        },
      };
      const { data } = await api.post("clinics/sign_up", request);
      if (data.data) {
        setGlobalUser(data.data);
        return true;
      } else {
        throw new Error(JSON.stringify(data.error));
      }
    } catch (error) {
      toast.error("Não foi possível completar seu registro");

      if (error.response) {
        error.response.data.errors.forEach(er => {
          if (er.title === "Email já está em uso") {
            toast.error(er.title);
          }
        });
      }

      return false;
    }
  }

  async function updateClinic(formValues, id) {
    try {
      const request = {
        data: {
          type: "clinic",
          attributes: {
            avatar: isValidImage(formValues.avatar),
            banner: formValues.bannerColor
              ? null
              : isValidImage(formValues.banner),
            responsibleAvatar: isValidImage(formValues.responsibleAvatar),
            secondaryBanner: isValidImage(formValues.secondaryBanner),
            bannerColor: formValues.bannerColor,
            name: formValues.name,
            description: formValues.description,
            email: formValues.email,
            phone: formValues.phone,
            password: formValues.password,
            password_confirmation: formValues.password_confirmation,
            documentNumber: formValues.documentNumber,
            hiddenFields: formValues.hiddenFields,
            paymentAccountAttributes: {
              id: authData.paymentAccount.id,
              documentNumber: formValues.paymentDocumentNumber,
              name: formValues.paymentName,
              bankAg: formValues.bankAg,
              accountType: formValues.accountType,
              bankCc: formValues.bankCc,
              bankCode: formValues.bankCode,
              agenciaDv:
                formValues.agenciaDv !== "" ? formValues.agenciaDv : null,
              contaDv: formValues.contaDv,
            },
            addressAttributes: {
              id: authData.address.id,
              cep: formValues.cep,
              street: formValues.street,
              neighborhood: formValues.neighborhood,
              country: formValues.country,
              city: formValues.city,
              state: formValues.state,
              street_number: formValues.street_number,
              complement: formValues.complement,
            },
            responsibleName: formValues.responsibleName,
            responsibleDocumentNumber: formValues.responsibleDocumentNumber,
            responsibleAbout: formValues.responsibleAbout,
            acceptCredit: formValues.acceptCredit,
            acceptPix: formValues.acceptPix,
            postPaid: formValues.postPaid,
            prePaid: formValues.prePaid,
            postPaidDay: formValues.postPaidDay,
            externalPayment: formValues.externalPayment,
            trialNoticeSeen: formValues.trialNoticeSeen,
            allmindsBenefit: false,
          },
        },
      };
      if (authData.documentNumber === formValues.documentNumber)
        delete request.data.attributes.documentNumber;
      if (
        authData.paymentAccount.documentNumber ===
        formValues.paymentDocumentNumber
      )
        delete request.data.attributes.paymentAccountAttributes.documentNumber;

      const { data } = await api.put(`/clinics/${id}`, request);
      if (data.data) {
        setGlobalUser(data.data);
        return true;
      } else {
        throw new Error(JSON.stringify(data.error));
      }
    } catch (error) {
      if (error.response.data.errors) {
        error.response.data.errors.forEach(er => {
          if (er.title === "Email já está em uso") {
            toast.error(er.title);
            return false;
          }
          if (er.id.includes("document_number")) {
            toast.error("Este CPF já está em uso! Forneça outro CPF.");
            return false;
          }
        });
      } else {
        toast.error("Não foi possível atualizar seu registro");
        return false;
      }
    }
  }

  async function forgetPassword(email, type) {
    try {
      const data = await api.post(`/${type}/auth/password`, {
        email: email,
        app: true,
      });
      if (data.data) {
        return data.data;
      }
    } catch (error) {
      return { success: false, message: "Email não encontrado!" };
    }
  }

  async function updatePassword(formValues, type, userId) {
    const request = {
      data: {
        type: type,
        attributes: {
          password: formValues.password,
          new_password: formValues.newPassword,
        },
      },
    };

    try {
      const { data } = await api.patch(
        `/${type}s/${userId}/update_password`,
        request,
      );
      return data;
    } catch (error) {
      return { error };
    }
  }

  if (loading) {
    return <LoaderSpinner />;
  }

  return (
    <AuthContext.Provider
      value={{
        deleteProfessional,
        forgetPassword,
        loading,
        professionalUpdateAdress,
        professionalUpdateBasicData,
        professionalUpdateProfessionalInfos,
        publicProfile,
        setAuthData,
        setLoading,
        signIn,
        signOut,
        signUpClient,
        signUpClinic,
        signUpProfessional,
        updateClient,
        updateClientOnboarding,
        updateClinic,
        updatePassword,
        updateProfessional,
        user: authData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
