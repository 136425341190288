/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { FooterButtons } from "../../../components/FooterButtons";
import FormikControl from "../../../components/FormikControl";
import { InputWrapper } from "../../../components/FormikElements/styles";
import { CheckBoxInput } from "../../../components/Input";
import api from "../../../services/api";
import { PtBr } from "../../../services/pt_br";
import { TextError } from "../../../styles/globalStyles";
import handleErrors from "../../../utils/getValuesErrors";
import {
  LoginLink,
  LoginParagraph,
  LoginTitle,
} from "../../Public/Login/styles";

const BasicRegistrationValues = [
  "firstname",
  "lastname",
  "email",
  "documentNumber",
  "birthday",
  "phone",
  "password",
  "confirm_password",
];

export default function BasicRegistrationProfessionalScreen({
  clinic,
  form,
  next,
}) {
  const {
    values,
    errors,
    touched,
    setValues,
    handleChange,
    handleBlur,
    validateForm,
  } = form;
  const history = useHistory();

  useEffect(() => {
    validateForm();
  }, []);

  const checkEmail = async email => {
    await api.post("/professionals/check_email", {
      email: email,
    });
  };

  const handleContinue = async () => {
    if (!handleErrors(errors, BasicRegistrationValues)) {
      try {
        await checkEmail(values.email);
        setValues({
          ...values,
          birthday: values.birthday,
        });
        next();
      } catch (error) {
        if (error.response) {
          if (error.response.data.available === false) {
            toast.error("Email já está em uso!");
          }
        } else {
          toast.error(
            "Ocorreu um erro no formulário, verifique os dados inseridos!",
          );
        }
      }
    }
  };

  return (
    <InputWrapper>
      <LoginTitle>Dados Básicos</LoginTitle>
      <FormikControl
        bgColor="#fff"
        upLabel
        labelWeight="600"
        labelSize="0.9rem"
        labelFamily="Poppins"
        control="input"
        name="firstName"
        value={values.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
        label={PtBr.register_input_firstname}
      />
      <TextError>
        {errors.firstName && touched.firstName && errors.firstName}
      </TextError>

      <FormikControl
        bgColor="#fff"
        upLabel
        labelWeight="600"
        labelSize="0.9rem"
        labelFamily="Poppins"
        control="input"
        name="lastName"
        value={values.lastName}
        onChange={handleChange}
        onBlur={handleBlur}
        label={PtBr.register_input_lastname}
      />
      <TextError>
        {errors.lastName && touched.lastName && errors.lastName}
      </TextError>

      {clinic && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "1rem",
            width: "100%",
          }}
        >
          <FormikControl
            bgColor="#fff"
            upLabel
            labelWeight="600"
            labelSize="0.9rem"
            labelFamily="Poppins"
            control="input"
            name="member"
            value={values?.clinicName}
            label={PtBr.register_input_clinic}
            disabled
          />
        </div>
      )}

      {values.hasSocialName && (
        <FormikControl
          bgColor="#fff"
          upLabel
          labelWeight="600"
          labelSize="0.9rem"
          labelFamily="Poppins"
          control="input"
          name="socialName"
          value={values.socialName}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Nome Social"
        />
      )}

      <CheckBoxInput
        name="hasSocialName"
        checked={values.hasSocialName}
        onChange={handleChange}
        onBlur={handleBlur}
        label="Tem nome social?"
        style={{ marginBottom: "0.5rem" }}
      />
      <TextError>
        {errors.hasSocialName && touched.hasSocialName && errors.hasSocialName}
      </TextError>

      <FormikControl
        bgColor="#fff"
        upLabel
        labelWeight="600"
        labelSize="0.9rem"
        labelFamily="Poppins"
        control="input"
        name="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        label={PtBr.register_input_email}
      />
      <TextError>{errors.email && touched.email && errors.email}</TextError>

      <FormikControl
        control="date"
        name="birthday"
        value={values.birthday}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleBlur}
        label="Data de nascimento"
        labelWeight="600"
        labelSize="0.9rem"
        labelFamily="Poppins"
      />
      <TextError>
        {errors.birthday && touched.birthday && errors.birthday}
      </TextError>

      <FormikControl
        bgColor="#fff"
        upLabel
        labelWeight="600"
        labelSize="0.9rem"
        labelFamily="Poppins"
        control="input"
        name="phone"
        mask="(99)99999-9999"
        value={values.phone}
        onChange={handleChange}
        onBlur={handleBlur}
        label="Número de telefone"
      />
      <TextError>{errors.phone && touched.phone && errors.phone}</TextError>

      <FormikControl
        bgColor="#fff"
        upLabel
        labelWeight="600"
        labelSize="0.9rem"
        labelFamily="Poppins"
        control="input"
        name="documentNumber"
        mask="999.999.999-99"
        value={values.documentNumber}
        onChange={handleChange}
        onBlur={handleBlur}
        label={PtBr.register_input_cpf_rg}
      />
      <TextError>
        {errors.documentNumber &&
          touched.documentNumber &&
          errors.documentNumber}
      </TextError>

      <FormikControl
        bgColor="#fff"
        upLabel
        labelWeight="600"
        labelSize="0.9rem"
        labelFamily="Poppins"
        type="password"
        control="input"
        name="password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        label={PtBr.register_input_password}
      />
      <TextError>
        {errors.password && touched.password && errors.password}
      </TextError>

      <FormikControl
        bgColor="#fff"
        upLabel
        labelWeight="600"
        labelSize="0.9rem"
        labelFamily="Poppins"
        type="password"
        control="input"
        name="confirm_password"
        value={values.confirm_password}
        onChange={handleChange}
        onBlur={handleBlur}
        label={PtBr.register_input_confirm_password}
      />
      <TextError>
        {errors.confirm_password &&
          touched.confirm_password &&
          errors.confirm_password}
      </TextError>

      <FooterButtons
        disableGoBack={true}
        next={handleContinue}
        warning={true}
      />

      <LoginParagraph style={{ marginTop: "2rem" }}>
        Já possui uma conta?{" "}
        <LoginLink
          type="button"
          onClick={() => history.push("/profissional/conectar-se")}
        >
          Entrar
        </LoginLink>
      </LoginParagraph>
    </InputWrapper>
  );
}
