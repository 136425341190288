/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AllPagesPDFViewer from "../../../components/AllPagesPDF";
import { FooterButtons } from "../../../components/FooterButtons";
import FormikControl from "../../../components/FormikControl";
import { InputWrapper } from "../../../components/FormikElements/styles";
import LoaderSpinner from "../../../components/LoaderSpinner";
import api from "../../../services/api";
import theme from "../../../theme";
import handleErrors from "../../../utils/getValuesErrors";
import { TextError } from "../../Professional/Config/styles";
import { LoginTitle } from "../../Public/Login/styles";
import {
  ButtonWrapper,
  ContinueButton,
  TermContainer,
  TermContent,
  TermDocument,
  TermTitle,
} from "./styles";

export default function AcceptanceTerm({ form, goBack }) {
  const { values, setFieldValue, submitForm, errors, touched } = form;
  const [loading, setLoading] = useState(true);
  const [notReadTerm, setNotReadTerm] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const fetchPDF = async () => {
      try {
        const { data } = await api.get("/terms", {
          signal: controller.signal,
        });
        if (data.data) {
          setFieldValue("termId", data.data.attributes.id);
        }
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error("Não foi possível carregar o termo");
      } finally {
        setLoading(false);
      }
    };
    fetchPDF();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (!values.read && values.accepted) {
      toast.info("Para prosseguir, leia e aceite o termo de uso.", {
        position: "top-center",
        autoClose: 5000,
      });
      setFieldValue("accepted", false);
    }
  }, [values.accepted]);

  function onSubmit() {
    if (!handleErrors(errors, null)) {
      submitForm();
    }
  }

  return (
    <InputWrapper>
      <LoginTitle style={{ textAlign: "center" }}>Termo de Aceite</LoginTitle>
      <TermTitle>Termo do aplicativo</TermTitle>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <TermContainer>
          <TermContent>
            <TermDocument
              id="pdf-document"
              onScroll={({ target }) => {
                const { scrollHeight, scrollTop, clientHeight } = target;
                const READ =
                  scrollHeight - Math.ceil(scrollTop + clientHeight) <= 10;
                if (READ) {
                  setNotReadTerm(false);
                  setFieldValue("read", true);
                }
              }}
            >
              <AllPagesPDFViewer />
            </TermDocument>

            <ButtonWrapper>
              <FormikControl
                labelSize="0.9rem"
                labelWeight="600"
                labelFamily="Poppins"
                control="checkbox"
                name="read"
                label="Li o termo"
                disabled={notReadTerm}
              />
              <TextError>
                {errors.read && touched.read && errors.read}
              </TextError>
              <FormikControl
                labelSize="0.9rem"
                labelWeight="600"
                labelFamily="Poppins"
                shadow
                control="checkbox"
                name="accepted"
                label="Aceito o termo"
              />
              <TextError>
                {errors.accepted && touched.accepted && errors.accepted}
              </TextError>

              <div
                style={{
                  alignItems: "flex-start",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <ContinueButton
                  id="finish-register"
                  bgColor={theme.secondary}
                  disabled={!values.accepted || !values.read}
                  onClick={() =>
                    values.accepted && values.read
                      ? onSubmit()
                      : toast.error(
                          "É necessário confirmar que o termo foi lido e aceito antes de prosseguir.",
                        )
                  }
                  type="button"
                >
                  Finalizar cadastro
                </ContinueButton>
                <FooterButtons
                  goBack={() => goBack(values.clinicId)}
                  disableNext={true}
                />
              </div>
            </ButtonWrapper>
          </TermContent>
        </TermContainer>
      )}
    </InputWrapper>
  );
}
