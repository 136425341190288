/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button as ButtonMaterial,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { MonetizationOn } from "@mui/icons-material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import * as BsIcon from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useAuth from "../../hooks/auth";
import useEvent from "../../hooks/event";
import NoProfile from "../../images/noAvatar.png";
import PostImg from "../../images/postImg.png";
import { addZero } from "../../services";
import api from "../../services/api";
import theme from "../../theme";
import convertCurrencyPtBr from "../../utils/convertCurrencyPtBr";
import { getCancellationStatus } from "../EventElements";
import { BoxCalendarCardInfo, ModalAvatar } from "../EventElements/styles";
import FormikControl from "../FormikControl";
import PdfGenerator from "../PDFDownload";
import { handleUserNames } from "../UserElements";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  BABankName,
  BABankNumber,
  BFontBold,
  BanckAccount,
  BannerContainer,
  BannerDescription,
  BoldText,
  BoldTextWhite,
  BookOpenIcon,
  BoxWrapper,
  Button,
  ButtonWrapper,
  CameraIcon,
  ClinicImageWrapper,
  CloseIcon,
  ColumnItem,
  ColumnItemStatement,
  Container,
  CreditCard,
  CustomPackagesMain,
  DeleteButton,
  DeleteIcon,
  DiaryButtonDelete,
  DiaryWrapper,
  DoteIcon,
  EditButton,
  EditIcon,
  FavoriteIcon,
  FavoriteIconSelected,
  Finance,
  FinanceInformation,
  FinancePayment,
  FinanceType,
  GFontBold,
  IconArea,
  IconAreaFinance,
  IconAreaModal,
  Image,
  InfoIcon,
  Invoice,
  InvoiceButton,
  InvoiceInformation,
  InvoiceType,
  LightText,
  LightTextWhite,
  LinkArea,
  MFontFinance,
  MapPinIcon,
  MesagensWrapper,
  Message,
  MessageButton,
  MessageContent,
  MessagelInformation,
  MessagelInformationAnnotation,
  ModalContent,
  NotificationContent,
  OpenedContent,
  PatitentImage,
  PayInIcon,
  PayOutIcon,
  PaymentIcon,
  PostDescription,
  PostImage,
  ProfessionalContent,
  ProfessionalContentSchedules,
  ProfessionalInformation,
  ProfessionalSchedule,
  ReviewContainer,
  ReviewContent,
  ReviewImage,
  RowItemPatient,
  RowItemSchedule,
  RowLine,
  SFontDark,
  SMFontBDark,
  SMFontFinanceDarkGray,
  SMFontInvoiceDarkGray,
  SMFontInvoiceSecondary,
  StatementsWrapper,
  StatusTag,
  UnViewCardIcon,
  ViewCardIcon,
  Wrapper,
} from "./styles";

export function PostRowItem({ ...props }) {
  const { item, type, onClick } = props;
  const { attributes } = item;
  const { title, comments, description } = attributes || " ";

  const renderFooter = (type, text) => {
    switch (type) {
      case "itens":
        return (
          <RowLine>
            <LightText>{`${text} itens`}</LightText>
          </RowLine>
        );
      case "book-open":
        return (
          <RowLine>
            <BookOpenIcon />
            <LightText>{`${text} comments`}</LightText>
          </RowLine>
        );
      default:
        return null;
    }
  };

  const trimString = (string, length) => {
    if (string?.length > length) {
      return `${string.substring(0, length - 3)}...`;
    } else {
      return string;
    }
  };

  return (
    <Container onClick={() => onClick(10)}>
      <PostImage src={PostImg} />
      <PostDescription>
        <BoldText>{trimString(description || "Sem descrição", 100)}</BoldText>
        {title && <LightText>{title}</LightText>}
        {renderFooter(type, comments?.length)}
      </PostDescription>
    </Container>
  );
}

export function CategoryRowItem({ ...props }) {
  const { item, type, onClick } = props;
  const { attributes } = item;
  const { title, body, postCount, image } = attributes || " ";
  const renderFooter = (type, text) => {
    switch (type) {
      case "itens":
        return (
          <RowLine>
            <LightText>{`${text} itens`}</LightText>
          </RowLine>
        );
      case "book-open":
        return (
          <RowLine>
            <BookOpenIcon />
            <LightText>{`${text} comments`}</LightText>
          </RowLine>
        );
      default:
        return null;
    }
  };

  if (item === "noCategory") {
    return (
      <Container onClick={() => onClick("noCategory")}>
        <PostImage src={image || PostImg} />
        <PostDescription>
          <BoldText>{body}</BoldText>
          {<LightText>{"Sem categoria"}</LightText>}
          <RowLine>
            <LightText>{` `}</LightText>
          </RowLine>
        </PostDescription>
      </Container>
    );
  }

  return (
    <Container onClick={() => onClick(item.id)}>
      <PostImage src={image || PostImg} />
      <PostDescription>
        <BoldText>{body}</BoldText>
        {title && <LightText>{title}</LightText>}
        {renderFooter(type, postCount)}
      </PostDescription>
    </Container>
  );
}

export function NotificationsItemList({ ...props }) {
  const {
    data: { attributes },
    handleClick,
  } = props;

  return (
    <NotificationContent
      read={attributes.readed}
      onClick={() => handleClick(props.data.id)}
    >
      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          flexShrink: "0",
          justifyContent: "center",
          width: "60%",
        }}
      >
        <BFontBold className="title">{attributes?.title}</BFontBold>
        <p className="subtitle">{attributes?.subtitle?.slice(0, 30)}...</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexShrink: "0",
          alignItems: "flex-end",
          justifyContent: "flex-start",
          width: "40%",
        }}
      >
        <SFontDark style={{ flexShrink: "0" }}>
          {moment(attributes?.createdAt).format("DD/MM/YYYY")}
        </SFontDark>
        <SFontDark style={{ fontWeight: "600", flexShrink: "0" }}>
          {attributes?.readed === null ? "Nova" : "Lida"}
        </SFontDark>
      </div>
    </NotificationContent>
  );
}

export function PostBanner({ ...props }) {
  const { item } = props;
  const { attributes } = item;
  const { title, body, image } = attributes || " ";
  return (
    <BannerContainer img={image || PostImg}>
      <BannerDescription>
        <BoldTextWhite>{title}</BoldTextWhite>
        <LightTextWhite>{body}</LightTextWhite>
      </BannerDescription>
    </BannerContainer>
  );
}

export function PacientListItem({ ...props }) {
  const { data, width } = props;
  const { client, professional, lastSchedule } = data.attributes;
  const userType = client || professional;
  const [modalInfo, setModalInfo] = useState(false);
  const isNext = moment(lastSchedule?.startDate).toDate() >= moment().toDate();
  const age = moment().diff(moment(userType?.birthday), "years");

  return (
    <OpenedContent>
      <PatitentImage>
        <ProfileImage
          size={width <= 480 ? "40px" : "50px"}
          src={userType?.avatar}
          radius={"50%"}
        />
      </PatitentImage>
      <ProfessionalContent>
        <ProfessionalInformation>
          <ColumnItem>
            <BFontBold className="title">{handleUserNames(userType)}</BFontBold>
            <SFontDark date={"true"}>
              {lastSchedule?.startDate && isNext
                ? `${moment(lastSchedule?.startDate, "YYYY-MM-DD").format(
                    "DD/MM/YYYY",
                  )} às ${moment(lastSchedule?.startDate, "HH:mm:ss").format(
                    "HH:mm",
                  )}`
                : "Sem agendamentos"}
            </SFontDark>
          </ColumnItem>
          <ColumnItem>
            <SFontDark>{userType?.email}</SFontDark>
            <SFontDark>{userType?.phone}</SFontDark>
          </ColumnItem>
        </ProfessionalInformation>
      </ProfessionalContent>
      <RowItemPatient>
        <Tooltip
          title={`Infomações do ${client ? "paciente" : "profissional"}`}
        >
          <MessageButton
            backgroundColor="#fff"
            onClick={() => setModalInfo(true)}
          >
            <InfoIcon />
          </MessageButton>
        </Tooltip>
        {/* {user.type === "professional" && lastSchedule?.startDate && (
          <Tooltip title="Abrir prontuário">
            <MessageButton
              backgroundColor="#fff"
              onClick={() =>
                history.push(`/professional/medical_record/${data.id}`)
              }
            >
              <EditIcon />
            </MessageButton>
          </Tooltip>
        )} */}
      </RowItemPatient>

      {modalInfo && (
        <Modal open>
          <BoxWrapper>
            <IconAreaModal>
              <CloseIcon onClick={() => setModalInfo(false)} />
            </IconAreaModal>
            <ModalContent>
              <ModalContent>
                <GFontBold style={{ marginBottom: "1.5rem" }}>
                  {`Infomações do ${client ? "paciente" : "profissional"}`}
                </GFontBold>
                <PatitentImage style={{ marginBottom: "1rem" }}>
                  <ProfileImage
                    size="100px"
                    src={userType?.avatar}
                    radius={"50%"}
                  />
                </PatitentImage>
                <BFontBold>Nome: {userType?.firstName}</BFontBold>
                {age >= 18 && (
                  <Fragment>
                    <BFontBold>Sobrenome: {userType?.lastName}</BFontBold>
                    <BFontBold>CPF: {userType?.documentNumber}</BFontBold>
                  </Fragment>
                )}
                {age < 18 && <BFontBold>Idade: {age} anos</BFontBold>}

                {age >= 18 && (
                  <Fragment>
                    <BFontBold>{userType?.email}</BFontBold>
                    <BFontBold>{userType?.phone}</BFontBold>
                  </Fragment>
                )}
                {client && age < 18 && (
                  <Fragment>
                    <GFontBold style={{ margin: "1.5rem 0" }}>
                      Infomações do Responsável
                    </GFontBold>
                    <BFontBold>
                      Nome: {userType?.legal_guardian_full_name}
                    </BFontBold>
                    <BFontBold>
                      E-mail: {userType?.legal_guardian_email}
                    </BFontBold>
                    <BFontBold>
                      Telefone: {userType?.legal_guardian_phone}
                    </BFontBold>
                    <BFontBold>
                      CPF: {userType?.legal_guardian_document_number}
                    </BFontBold>
                  </Fragment>
                )}
              </ModalContent>
            </ModalContent>
          </BoxWrapper>
        </Modal>
      )}
    </OpenedContent>
  );
}

export function MessageItemList({ ...props }) {
  const { data, onClick, selected, clientPath, notifications } = props;
  const { client, lastMessage, professional } = data.attributes;
  const { user: profile } = useAuth();
  const defaultType = localStorage.getItem("type");
  const type = profile.type || defaultType;
  const user = type !== "client" ? client : professional;
  const trimString = (string, length) => {
    if (string?.length > length) {
      return `${string.substring(0, length - 3)}...`;
    } else {
      return string;
    }
  };
  const [notificationsLength, setNotificationLength] = useState(0);

  useEffect(() => {
    setNotificationLength(notifications?.length);
    if (clientPath?.id === data.attributes?.client?.id) {
      onClick(user);
    }
  }, []);

  const formatDate = date => moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");

  const handleReadNotification = async notificationId => {
    try {
      const { data } = await api.get(
        `${defaultType}s/notifications/${notificationId}`,
      );
      if (data) {
        setNotificationLength(0);
      }
    } catch (error) {
      throw new error("Erro ao ler notificação");
    }
  };

  return (
    <MesagensWrapper>
      <MessageContent
        active={
          defaultType === "client"
            ? selected?.id === data?.attributes?.professional?.id
              ? "true"
              : "false"
            : selected?.id === data?.attributes?.client?.id
            ? "true"
            : "false"
        }
      >
        <MessagelInformation>
          <ProfileImage
            style={{ marginRight: "2rem" }}
            size="40px"
            src={user?.avatar}
          />
          <ColumnItem className="profile-text">
            <BFontBold className="--text">
              {trimString(handleUserNames(user), 16)}
            </BFontBold>
            {lastMessage?.createdAt ? (
              <SFontDark date={"true"} className="--text">
                {formatDate(lastMessage?.createdAt)} às{" "}
                {moment(lastMessage?.createdAt).format("HH:mm")}
              </SFontDark>
            ) : (
              <SFontDark date={"true"} className="--text">
                Sem Mensagens
              </SFontDark>
            )}
          </ColumnItem>
        </MessagelInformation>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {notificationsLength > 0 && (
            <Tooltip
              title={`Você tem ${notifications?.length} novas mensagens`}
            >
              <p style={{ color: "blue" }}>◉</p>
            </Tooltip>
          )}
          <Tooltip title="Abrir mensagem">
            <MessageButton
              backgroundColor="#fff"
              onClick={() => {
                onClick(user);
                notifications.map(notification =>
                  handleReadNotification(notification.id),
                );
              }}
            >
              <Message />
            </MessageButton>
          </Tooltip>
        </div>
      </MessageContent>
    </MesagensWrapper>
  );
}

export function DiaryItemList({ ...props }) {
  const { data, selectNote, editNote, deleteNote, selected } = props;

  return (
    <DiaryWrapper>
      <MessageContent
        onClick={() => {
          selectNote(data);
        }}
        active={selected?.id === data?.id ? "true" : "false"}
      >
        <MessagelInformationAnnotation
          active={selected?.id === data?.id ? "true" : "false"}
        >
          <BFontBold className="--date">
            {(data.createdAt &&
              moment(data.createdAt).format("DD [de] MMMM")) ||
              "Não há data"}
          </BFontBold>
          <SFontDark className="--title">
            {data.title || "Sem Título"}
          </SFontDark>
          {false && <DoteIcon />}
        </MessagelInformationAnnotation>
        <IconArea
          style={{ marginRight: "0.5rem" }}
          onClick={() => editNote(data)}
          active={selected?.id === data?.id ? "true" : "false"}
        >
          <EditIcon active={selected?.id === data?.id ? "true" : "false"} />
        </IconArea>
        <IconArea
          onClick={() => deleteNote(data)}
          active={selected?.id === data?.id ? "true" : "false"}
        >
          <DiaryButtonDelete
            active={selected?.id === data?.id ? "true" : "false"}
          />
        </IconArea>
      </MessageContent>
    </DiaryWrapper>
  );
}

export function ProfileImage({ ...props }) {
  const { src, size, radius, ...rest } = props;
  return (
    <Image src={src ? src : NoProfile} size={size} radius={radius} {...rest} />
  );
}

export function FinanceItemList({ ...props }) {
  const { data } = props;
  const history = useHistory();
  const financeDate = moment(data.dateCreated, "YYYY-MM-DD").format(
    "DD/MM/YYYY",
  );

  return (
    <FinancePayment>
      <SMFontFinanceDarkGray>
        {`#${data.pagarmeTransactionId} • ${financeDate}`}
      </SMFontFinanceDarkGray>
      <FinanceType className="client">
        <ProfileImage size="40px" src={data.client?.avatar} />
        <SMFontFinanceDarkGray style={{ marginLeft: "1rem" }}>
          {`${data.client?.firstName} ${data.client?.lastName.split(" ")[0]}`}
        </SMFontFinanceDarkGray>
      </FinanceType>
      <MFontFinance
        className="--finance-type"
        color={data.type === "refund" ? "red" : "black"}
      >{`${convertCurrencyPtBr(data?.total)}`}</MFontFinance>
      <InvoiceButton onClick={() => history.push("/professional/invoices")}>
        Anexar nota fiscal
      </InvoiceButton>
    </FinancePayment>
  );
}

export function CreditCardListItem({ ...props }) {
  const { setInfoCardCredit } = useEvent();
  const {
    data,
    handleClickDefault,
    handleDeleteCard,
    filterDefaults,
    selected,
    setSelected,
    exclude,
  } = props;
  const {
    id,
    brand,
    holderName,
    lastDigits,
    default: defaults,
    subscription,
    cardExpirationDate,
  } = data.attributes;
  const [modalDelete, setModalDelete] = useState(false);
  const [viewCard, setViewCard] = useState(false);
  const cardId = String(id);

  useEffect(() => {
    if (defaults) setSelected(String(cardId));
    setInfoCardCredit(String(cardId));
  }, [data]);

  useEffect(() => {
    setViewCard(false);
  }, [selected]);

  return (
    <CreditCard
      selected={selected === cardId}
      active={filterDefaults?.length !== 0}
      onClick={() => {
        setSelected(String(cardId));
        setInfoCardCredit(String(cardId));
      }}
    >
      <div className="brand">
        <SMFontFinanceDarkGray className="text-card">
          {`${brand.toUpperCase()} - **** **** **** ${lastDigits}`}
        </SMFontFinanceDarkGray>
        {viewCard && selected === cardId && (
          <Fragment>
            <SMFontFinanceDarkGray className="text-card">
              {`Nome: ${holderName}`}
            </SMFontFinanceDarkGray>
            {cardExpirationDate && (
              <SMFontFinanceDarkGray className="text-card">
                {`Data de expiração: ${cardExpirationDate}`}
              </SMFontFinanceDarkGray>
            )}
          </Fragment>
        )}
      </div>
      {exclude ? (
        defaults && (
          <div className="default">
            {selected === cardId && (
              <IconAreaFinance onClick={() => setViewCard(!viewCard)}>
                <Tooltip title="Ver dados do cartão.">
                  {viewCard ? <ViewCardIcon /> : <UnViewCardIcon />}
                </Tooltip>
              </IconAreaFinance>
            )}
            <IconAreaFinance
              selected={selected === cardId}
              onClick={() => !defaults && handleClickDefault(cardId)}
            >
              <Tooltip title="Cartão padrão.">
                {defaults ? <FavoriteIconSelected /> : <FavoriteIcon />}
              </Tooltip>
            </IconAreaFinance>
          </div>
        )
      ) : (
        <div className="default">
          {selected === cardId && (
            <IconAreaFinance onClick={() => setViewCard(!viewCard)}>
              <Tooltip title="Ver dados do cartão.">
                {viewCard ? <ViewCardIcon /> : <UnViewCardIcon />}
              </Tooltip>
            </IconAreaFinance>
          )}
          <IconAreaFinance
            selected={selected === cardId}
            onClick={() => !defaults && handleClickDefault(cardId)}
          >
            <Tooltip
              title={
                defaults ? "Cartão padrão." : "Definir cartão como padrão."
              }
            >
              {defaults ? <FavoriteIconSelected /> : <FavoriteIcon />}
            </Tooltip>
          </IconAreaFinance>
          {defaults || subscription ? (
            <Fragment></Fragment>
          ) : (
            <IconAreaFinance
              selected={selected === cardId}
              onClick={() => setModalDelete(true)}
            >
              <Tooltip title="Excluir cartão.">
                <DeleteIcon />
              </Tooltip>
            </IconAreaFinance>
          )}
        </div>
      )}
      {modalDelete && (
        <Modal open>
          <BoxWrapper>
            <Typography variant="h6" sx={{ mt: 2 }}>
              {" "}
              O cartão será excluído. Essa ação é irreversível, deseja
              continuar?{" "}
            </Typography>

            <Grid
              direction="row"
              justifyContent="center"
              alignItems="center"
              container
            >
              <ButtonMaterial
                sx={{ m: 1 }}
                onClick={() => {
                  setModalDelete(false);
                }}
                size="small"
                variant="contained"
              >
                Não
              </ButtonMaterial>
              <ButtonMaterial
                sx={{ m: 1 }}
                onClick={() => {
                  handleDeleteCard(cardId);
                  setModalDelete(false);
                }}
                size="small"
                variant="contained"
              >
                Sim
              </ButtonMaterial>
            </Grid>
          </BoxWrapper>
        </Modal>
      )}
    </CreditCard>
  );
}

export function PaymentHistoryItemList({ ...props }) {
  const { data } = props;
  const financeDate = data?.attributes
    ? moment(data?.attributes?.createdAt, "YYYY-MM-DD").format("DD/MM/YYYY")
    : moment(data?.created_at, "YYYY-MM-DD").format("DD/MM/YYYY");

  return (
    <Finance payment="true">
      <FinanceType className="primary">
        <SMFontFinanceDarkGray>
          {`#${data?.id || data?.attributes?.id} • ${financeDate}`}
        </SMFontFinanceDarkGray>
      </FinanceType>

      <FinanceType className="client">
        <SMFontFinanceDarkGray
          style={{
            fontSize: "1.1rem",
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          {data?.plan_name || data?.attributes?.professional?.firstName}
        </SMFontFinanceDarkGray>
      </FinanceType>

      <FinanceType className="secondary">
        <MFontFinance>
          {convertCurrencyPtBr(data?.amount || data?.attributes?.paymentAmount)}
        </MFontFinance>
      </FinanceType>
    </Finance>
  );
}

export function InvoiceItemList({ ...props }) {
  const { invoiceItem } = props;
  const date = new Date(invoiceItem.attributes.createdAt);

  const handleChangeInvoice = e => {
    const file = e.target.files[0];
    const filename = e.target.files[0].name;
    const extension = e.target.files[0].type;

    if (file) {
      const reader = new FileReader();
      reader.onload = _handleReaderLoaded.bind(this, filename, extension);
      reader.readAsBinaryString(file);
    }
  };

  const _handleReaderLoaded = (filename, extension, readerEvt) => {
    let bynaryString = readerEvt.target.result;
    uploadArchive(`data:${extension};base64,${btoa(bynaryString)}`);
  };

  const uploadArchive = async base64 => {
    const isBase64 = base64 => {
      const arr = base64.split(";base64,");
      return arr?.length > 1 ? true : false;
    };

    try {
      const request = {
        data: {
          type: "professionalInvoice",
          attributes: {
            document: base64
              ? isBase64(base64)
                ? { data: base64 }
                : undefined
              : undefined,
          },
        },
      };
      await api.put(`/professionals/invoices/${invoiceItem.id}`, request);
      window.location.reload(false);
    } catch (error) {
      toast.error(error);
      return false;
    }
  };

  return (
    <Invoice>
      <InvoiceType>
        <InvoiceType>
          <SMFontInvoiceSecondary>
            {date.toLocaleDateString()} •{" "}
            {convertCurrencyPtBr(invoiceItem.attributes.paymentAmount)}
          </SMFontInvoiceSecondary>
        </InvoiceType>
        <InvoiceType>
          <SMFontInvoiceDarkGray>
            {invoiceItem?.attributes?.client?.fullName}
          </SMFontInvoiceDarkGray>
        </InvoiceType>
      </InvoiceType>
      <InvoiceInformation>
        {invoiceItem?.attributes?.status === "disapproved" ? (
          <StatusTag color="#FD6492" bgcolor="#FFF1F3">
            <BsIcon.BsFillCircleFill color="#FD6492" size={12} />
            {invoiceItem?.attributes?.tStatus}
          </StatusTag>
        ) : invoiceItem?.attributes?.status === "waiting_send" ? (
          <StatusTag color="#426AFF" bgcolor="#F0F3FE">
            <BsIcon.BsFillCircleFill color="#426AFF" size={12} />
            {invoiceItem?.attributes?.tStatus}
          </StatusTag>
        ) : (
          <StatusTag color="#EDAD2E" bgcolor="#FFF8EB">
            {" "}
            <BsIcon.BsFillCircleFill color="#EDAD2E" size={12} />
            {invoiceItem?.attributes?.tStatus}
          </StatusTag>
        )}
        {invoiceItem.attributes.status === "waiting_send" ||
        invoiceItem.attributes.status === "disapproved" ? (
          <Button style={{ minWidth: "200px" }}>
            <div className="up-archive">
              <label>
                <input
                  type="file"
                  accept=".jpeg, .png, .jpg, .pdf"
                  onChange={handleChangeInvoice}
                />
                Upload de nota fiscal
              </label>
            </div>
          </Button>
        ) : (
          <Fragment></Fragment>
        )}
      </InvoiceInformation>
    </Invoice>
  );
}

export function ClientFinanceItemList({ ...props }) {
  const { data } = props;
  const date = new Date(data?.createdAt);
  const financeDate = `${addZero(date.getDate())}/${addZero(
    date.getMonth() + 1,
  )}/${date.getFullYear("yy")}`;
  const financeTime = date.toLocaleTimeString("pt-br", {
    minute: "2-digit",
    hour: "2-digit",
  });

  const Status = () => {
    switch (data.tStatus) {
      case "Pago":
        return <PayOutIcon />;
      case "Reembolsado":
        return <PayInIcon />;
      case "Aguardando pagamento":
        return (
          <PendingOutlinedIcon
            style={{ height: "27px", width: "27px", color: "gold" }}
          />
        );
      case "Recusado":
        return (
          <CancelOutlinedIcon
            style={{ height: "27px", width: "27px", color: theme.pink }}
          />
        );
      default:
        return (
          <CircleOutlinedIcon
            style={{ height: "27px", width: "27px", color: theme.primary }}
          />
        );
    }
  };

  if (data.tStatus !== "Reembolsado") {
    return (
      <Finance>
        <FinanceType>
          {Status()}
          <MFontFinance>{`${convertCurrencyPtBr(
            data?.paymentAmount,
          )}`}</MFontFinance>
        </FinanceType>
        <FinanceInformation style={{ alignItems: "center" }}>
          <SMFontFinanceDarkGray>{`${data.professional.firstName} ${data.professional.lastName}`}</SMFontFinanceDarkGray>
          <SMFontFinanceDarkGray>{`${
            data.professional.email || ""
          }`}</SMFontFinanceDarkGray>
        </FinanceInformation>
        <FinanceType style={{ justifyContent: "flex-end", width: "30%" }}>
          <SMFontFinanceDarkGray>{`${data.pagarmeTransactionId}`}</SMFontFinanceDarkGray>
        </FinanceType>
        <FinanceInformation>
          {/* <SMFontFinanceSecondary>{type}</SMFontFinanceSecondary> */}
          <SMFontFinanceDarkGray>{`${data.tStatus}`}</SMFontFinanceDarkGray>
          <SMFontFinanceDarkGray>
            {`${financeDate.toString()} • ${financeTime}`}
          </SMFontFinanceDarkGray>
        </FinanceInformation>
      </Finance>
    );
  } else {
    return (
      <section>
        <Finance>
          <FinanceType>
            <PayInIcon />
            <MFontFinance>{`${convertCurrencyPtBr(
              data?.paymentAmount,
            )}`}</MFontFinance>
          </FinanceType>
          <FinanceInformation style={{ alignItems: "center" }}>
            <SMFontFinanceDarkGray>{`${data.professional.firstName} ${data.professional.lastName}`}</SMFontFinanceDarkGray>
            <SMFontFinanceDarkGray>{`${
              data.professional.email || ""
            }`}</SMFontFinanceDarkGray>
          </FinanceInformation>
          <FinanceType style={{ justifyContent: "flex-end", width: "30%" }}>
            <SMFontFinanceDarkGray>{`${data.pagarmeTransactionId}`}</SMFontFinanceDarkGray>
          </FinanceType>
          <FinanceInformation>
            {/* <SMFontFinanceSecondary>{type}</SMFontFinanceSecondary> */}
            <SMFontFinanceDarkGray>{`Reembolsado`}</SMFontFinanceDarkGray>
            <SMFontFinanceDarkGray>
              {`${financeDate.toString()} • ${financeTime}`}
            </SMFontFinanceDarkGray>
          </FinanceInformation>
        </Finance>
        <Finance>
          <FinanceType>
            <PayOutIcon />
            <MFontFinance>{`${convertCurrencyPtBr(
              data?.paymentAmount,
            )}`}</MFontFinance>
          </FinanceType>
          <FinanceInformation style={{ alignItems: "center" }}>
            <SMFontFinanceDarkGray>{`${data.professional.firstName} ${data.professional.lastName}`}</SMFontFinanceDarkGray>
            <SMFontFinanceDarkGray>{`${
              data.professional.email || ""
            }`}</SMFontFinanceDarkGray>
          </FinanceInformation>
          <FinanceType style={{ justifyContent: "flex-end", width: "30%" }}>
            <SMFontFinanceDarkGray>{`${data.pagarmeTransactionId}`}</SMFontFinanceDarkGray>
          </FinanceType>
          <FinanceInformation>
            {/* <SMFontFinanceSecondary>{type}</SMFontFinanceSecondary> */}
            <SMFontFinanceDarkGray>{`Pago`}</SMFontFinanceDarkGray>
            <SMFontFinanceDarkGray>
              {`${financeDate.toString()} • ${financeTime}`}
            </SMFontFinanceDarkGray>
          </FinanceInformation>
        </Finance>
      </section>
    );
  }
}

export function BankAccoutListItem({ ...props }) {
  const { data: account, selected, onClick } = props;
  function printOrNot(value) {
    if (value !== undefined || value === null || value === "") {
      return `-${value}`;
    }
    return "";
  }
  return (
    <BanckAccount selected={selected} onClick={onClick}>
      <BABankName>{account?.fullName}</BABankName>
      <BABankNumber>
        {`Ag: ${account?.bankAg}${printOrNot(account.agenciaDv)} | Conta: ${
          account?.bankCc
        }${printOrNot(account.contaDv)}`}
      </BABankNumber>
    </BanckAccount>
  );
}

export function ProfileReviewItemList({ ...props }) {
  const { data: review } = props;
  const [isAnonymous] = useState(review.client === null && review.anonymous);
  return (
    <ReviewContainer>
      <ReviewImage src={review?.client?.avatar || NoProfile} />
      <ReviewContent>
        <SMFontBDark>
          {isAnonymous ? "Anônimo" : handleUserNames(review.client)}
        </SMFontBDark>
        <LightText>{review.comment}</LightText>
      </ReviewContent>
    </ReviewContainer>
  );
}

export function SchedulesListItem({ ...props }) {
  const { data, schedule, report, past } = props;
  const history = useHistory();
  const { client, startDate, startTime, paymentStatus, payment } =
    data.attributes;
  const [modalInfo, setModalInfo] = useState(false);

  const formatDate = date => moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
  const formatHour = hour => moment(hour, "HH:mm:ss").format("HH:mm");
  const age = moment().diff(moment(client?.birthday), "years");

  return (
    <Wrapper schedule={schedule}>
      <OpenedContent schedule={schedule}>
        <PatitentImage>
          <ProfileImage
            size={schedule ? "40px" : "50px"}
            src={client?.avatar}
            radius="50%"
          />
        </PatitentImage>
        <ProfessionalContentSchedules>
          <ProfessionalSchedule>
            <ColumnItem>
              <BFontBold className={schedule ? "schedule-title" : "title"}>
                {handleUserNames(client)}
              </BFontBold>
              <SFontDark date={"true"}>
                {startDate && formatDate(startDate)} às {formatHour(startTime)}
              </SFontDark>
            </ColumnItem>
          </ProfessionalSchedule>
        </ProfessionalContentSchedules>
        <ProfessionalContentSchedules>
          <ProfessionalSchedule>
            <ColumnItem>
              <BFontBold className={schedule ? "schedule-title" : "title"}>
                Valor: {convertCurrencyPtBr(payment?.paymentAmount)}
              </BFontBold>
            </ColumnItem>
          </ProfessionalSchedule>
        </ProfessionalContentSchedules>
        <RowItemSchedule>
          <MessageButton
            backgroundColor="#fff"
            onClick={() => setModalInfo(true)}
          >
            <Tooltip title="Informações do Paciente">
              <InfoIcon />
            </Tooltip>
          </MessageButton>

          {!past && !report && paymentStatus === "paid" && (
            <LinkArea
              backgroundColor="#fff"
              target="_blank"
              disabled={
                getCancellationStatus(
                  data?.attributes.startDate,
                  data?.attributes.startTime,
                ) === "cancellable" ||
                getCancellationStatus(
                  data?.attributes.startDate,
                  data?.attributes.startTime,
                ) === "time_exceeded"
              }
              onClick={() => {
                getCancellationStatus(
                  data?.attributes.startDate,
                  data?.attributes.startTime,
                ) === "cancellable" &&
                  toast.warning("Esse agendamento ainda não está disponivel");
                if (
                  getCancellationStatus(
                    data?.attributes.startDate,
                    data?.attributes.startTime,
                  ) === "cancellable"
                ) {
                  toast.warning("Esse agendamento ainda não está disponivel");
                } else {
                  if (data?.attributes?.ownMeetingLink) {
                    window.open(data?.attributes?.ownMeetingLink, "_blank");
                  } else {
                    history.push({
                      pathname: `/professional/meeting`,
                      state: { schedule: { id: data.id, ...data?.attributes } },
                    });
                  }
                }
              }}
            >
              {data?.attributes?.appointmentType === "online" ? (
                <Tooltip title="Iniciar atendimento">
                  <CameraIcon className="--icon" />
                </Tooltip>
              ) : (
                <MapPinIcon className="--icon" />
              )}
            </LinkArea>
          )}

          {!report && (
            <MessageButton
              backgroundColor="#fff"
              onClick={() =>
                history.push({
                  pathname: `/professional/messages`,
                  state: { client },
                })
              }
            >
              <Tooltip title="Enviar mensagem">
                <Message />
              </Tooltip>
            </MessageButton>
          )}

          {paymentStatus !== "paid" ? (
            <MessageButton backgroundColor="#fff" disabled={true}>
              <Tooltip title="Pagamento não registrado">
                <PaymentIcon />
              </Tooltip>
            </MessageButton>
          ) : (
            report && <PdfGenerator id={data.id} />
          )}
        </RowItemSchedule>
      </OpenedContent>

      {modalInfo && (
        <Modal open>
          <BoxWrapper>
            <IconAreaModal>
              <CloseIcon onClick={() => setModalInfo(false)} />
            </IconAreaModal>
            <ModalContent>
              <GFontBold style={{ marginBottom: "1.5rem" }}>
                Infomações do paciente
              </GFontBold>
              <PatitentImage style={{ marginBottom: "1rem" }}>
                <ProfileImage
                  size="100px"
                  src={client?.avatar}
                  radius={"50%"}
                />
              </PatitentImage>
              <BFontBold>Nome: {client?.firstName}</BFontBold>
              {age >= 18 && (
                <Fragment>
                  <BFontBold>Sobrenome: {client?.lastName}</BFontBold>
                  <BFontBold>
                    CPF: {client?.document_number || client?.documentNumber}
                  </BFontBold>
                </Fragment>
              )}
              {age < 18 && <BFontBold>Idade: {age} anos</BFontBold>}

              {age >= 18 && (
                <Fragment>
                  <BFontBold>{client?.email}</BFontBold>
                  <BFontBold>{client?.phone}</BFontBold>
                </Fragment>
              )}

              {age < 18 && (
                <Fragment>
                  <GFontBold style={{ margin: "1.5rem 0" }}>
                    Infomações do Responsável
                  </GFontBold>
                  <BFontBold>
                    Nome: {client?.legal_guardian_full_name}
                  </BFontBold>
                  <BFontBold>E-mail: {client?.legal_guardian_email}</BFontBold>
                  <BFontBold>
                    Telefone: {client?.legal_guardian_phone}
                  </BFontBold>
                  <BFontBold>
                    CPF: {client?.legal_guardian_document_number}
                  </BFontBold>
                </Fragment>
              )}
            </ModalContent>
          </BoxWrapper>
        </Modal>
      )}
    </Wrapper>
  );
}

export function SchedulesListClinicItem({ ...props }) {
  const { setLoadingEvent } = useEvent();
  const { data, schedule } = props;
  const {
    appointmentType,
    client,
    externalPayment,
    payment,
    postPaid,
    professional,
    scheduleStatus,
    startDate,
    startTime,
  } = data.attributes;
  const [modalInfo, setModalInfo] = useState(false);
  const [modalConfirmPayment, setModalConfirmPayment] = useState(false);

  const formatDate = date => moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
  const formatHour = hour => moment(hour, "HH:mm:ss").format("HH:mm");

  const handleConfirmPayment = async () => {
    try {
      const { data: paymentData } = await api.patch(
        `/clinics/schedules/${data.id}/confirm-payment`,
      );
      if (paymentData) {
        setModalConfirmPayment(false);
        toast.success("Pagamento confirmado com sucesso");
      }
    } catch (error) {
      toast.error("Erro ao confirmar pagamento");
    } finally {
      setLoadingEvent(true);
    }
  };

  return (
    <Wrapper schedule={schedule}>
      <OpenedContent schedule={schedule}>
        <ClinicImageWrapper>
          <ProfileImage
            size={schedule ? "40px" : "50px"}
            src={client?.avatar}
            radius="50%"
          />
          <ProfileImage
            size={schedule ? "40px" : "50px"}
            src={professional?.avatar}
            radius="50%"
          />
        </ClinicImageWrapper>
        <ProfessionalContentSchedules>
          <ProfessionalSchedule>
            <ColumnItem>
              <BFontBold className={schedule ? "schedule-title" : "title"}>
                {handleUserNames(client)} - {handleUserNames(professional)}
              </BFontBold>
              <SFontDark date={"true"}>
                {startDate && formatDate(startDate)} às {formatHour(startTime)}
              </SFontDark>
            </ColumnItem>
          </ProfessionalSchedule>
        </ProfessionalContentSchedules>
        <ProfessionalContentSchedules>
          <ProfessionalSchedule>
            <ColumnItem>
              <BFontBold className={schedule ? "schedule-title" : "title"}>
                Valor: {convertCurrencyPtBr(payment?.paymentAmount)}
              </BFontBold>
            </ColumnItem>
          </ProfessionalSchedule>
        </ProfessionalContentSchedules>
        <RowItemSchedule>
          <MessageButton
            backgroundColor="#fff"
            onClick={() => setModalInfo(true)}
          >
            <Tooltip title="Informações do Paciente">
              <InfoIcon />
            </Tooltip>
          </MessageButton>

          {!payment?.paymentStatus?.includes("paid") && (
            <MessageButton
              backgroundColor="#fff"
              disabled={!externalPayment}
              onClick={() => setModalConfirmPayment(true)}
            >
              <Tooltip
                title={`Pagamento ${
                  (postPaid && "Pós Pago") ||
                  (externalPayment && "Externo") ||
                  "Pendente"
                }`}
              >
                {externalPayment ? (
                  <MonetizationOn
                    color="success"
                    style={{ fontSize: "1.2rem" }}
                  />
                ) : (
                  <PaymentIcon />
                )}
              </Tooltip>
            </MessageButton>
          )}
          {scheduleStatus === "Concluido" && <PdfGenerator id={data?.id} />}
        </RowItemSchedule>
      </OpenedContent>

      {modalInfo && (
        <Modal open>
          <BoxCalendarCardInfo>
            <IconAreaModal>
              <CloseIcon onClick={() => setModalInfo(false)} />
            </IconAreaModal>
            <Typography
              variant="p"
              sx={{
                fontWeight: "700",
                fontSize: "1.7rem",
              }}
            >
              Detalhes do agendamento
            </Typography>
            <Grid
              justifyContent="center"
              alignItems="center"
              container
              sx={{ mt: 2 }}
            >
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <ModalAvatar src={client?.avatar} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <ModalAvatar src={professional?.avatar} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    Paciente
                  </Typography>
                  <Typography variant="body1">
                    {client?.firstName} {client?.lastName}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    Profissional
                  </Typography>
                  <Typography variant="body1">
                    {professional?.firstName} {professional?.lastName}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    Email
                  </Typography>
                  <Typography variant="body1">{client?.email}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    Email
                  </Typography>
                  <Typography variant="body1">{professional?.email}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    Telefone
                  </Typography>
                  <Typography variant="body1">{client?.phone}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    Telefone
                  </Typography>
                  <Typography variant="body1">{professional?.phone}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    Dia
                  </Typography>
                  <Typography variant="body1">
                    {moment(startDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    Hora
                  </Typography>
                  <Typography variant="body1">
                    {moment(startTime, "HH:mm:ss").format("HH:mm")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    Status
                  </Typography>
                  <Typography variant="body1">
                    {scheduleStatus === "Pendente"
                      ? "Aguardando pagamento"
                      : "Pago"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    Tipo
                  </Typography>
                  <Typography variant="body1">
                    {appointmentType === "online" ? "Online" : "Presencial"}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </BoxCalendarCardInfo>
        </Modal>
      )}

      {modalConfirmPayment && (
        <Modal open>
          <BoxCalendarCardInfo>
            <IconAreaModal>
              <CloseIcon onClick={() => setModalConfirmPayment(false)} />
            </IconAreaModal>
            <Typography
              variant="p"
              sx={{
                fontWeight: "700",
                fontSize: "1.7rem",
              }}
            >
              Efetuar confirmação de pagamento?
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              width="70%"
              sx={{ mt: 10 }}
            >
              <ButtonMaterial
                sx={{ m: 1, width: 100 }}
                onClick={() => {
                  handleConfirmPayment();
                }}
                variant="contained"
              >
                Sim
              </ButtonMaterial>

              <ButtonMaterial
                sx={{ m: 1, width: 100 }}
                onClick={() => {
                  setModalConfirmPayment(false);
                }}
                color="error"
                variant="contained"
              >
                Não
              </ButtonMaterial>
            </Box>
          </BoxCalendarCardInfo>
        </Modal>
      )}
    </Wrapper>
  );
}

export function StatementListItem({ ...props }) {
  const { data } = props;
  const {
    description,
    amount,
    classification,
    createdAt,
    paidAt,
    additionalInfo,
    // transactionType,
  } = data;
  // const [modalInfo, setModalInfo] = useState(false);
  // const [modalConfirmPayment, setModalConfirmPayment] = useState(false);

  return (
    <Fragment>
      <StatementsWrapper>
        <ColumnItemStatement>
          <SMFontBDark>Descrição</SMFontBDark>
          <SFontDark>{description}</SFontDark>
        </ColumnItemStatement>

        <ColumnItemStatement>
          <SMFontBDark>Informações</SMFontBDark>
          <SFontDark>{additionalInfo}</SFontDark>
        </ColumnItemStatement>

        <ColumnItemStatement>
          <SMFontBDark>Data de Criação</SMFontBDark>
          <SFontDark>{createdAt}</SFontDark>
        </ColumnItemStatement>

        <ColumnItemStatement>
          <SMFontBDark>Valor</SMFontBDark>
          <SFontDark>{convertCurrencyPtBr(amount)}</SFontDark>
        </ColumnItemStatement>

        <ColumnItemStatement>
          <SMFontBDark>Classificação</SMFontBDark>
          <SFontDark>
            {classification === "expense" ? "Despesa" : "Receita"}
          </SFontDark>
        </ColumnItemStatement>

        <ColumnItemStatement>
          <SMFontBDark>Data de Pagamento</SMFontBDark>
          <SFontDark>{paidAt}</SFontDark>
        </ColumnItemStatement>
      </StatementsWrapper>

      {/* {modalInfo && (
        <Modal open>
          <BoxCalendarCardInfo>
            <IconAreaModal>
              <CloseIcon onClick={() => setModalInfo(false)} />
            </IconAreaModal>
            <Typography
              variant="p"
              sx={{
                fontWeight: "700",
                fontSize: "1.7rem",
              }}
            >
              Detalhes do agendamento
            </Typography>
            <Grid
              justifyContent="center"
              alignItems="center"
              container
              sx={{ mt: 2 }}
            >
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <ModalAvatar src={client?.avatar} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <ModalAvatar src={professional?.avatar} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    Paciente
                  </Typography>
                  <Typography variant="body1">
                    {client?.firstName} {client?.lastName}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    Profissional
                  </Typography>
                  <Typography variant="body1">
                    {professional?.firstName} {professional?.lastName}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    Email
                  </Typography>
                  <Typography variant="body1">{client?.email}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    Email
                  </Typography>
                  <Typography variant="body1">{professional?.email}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    Telefone
                  </Typography>
                  <Typography variant="body1">{client?.phone}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    Telefone
                  </Typography>
                  <Typography variant="body1">{professional?.phone}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    Dia
                  </Typography>
                  <Typography variant="body1">
                    {moment(startDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    Hora
                  </Typography>
                  <Typography variant="body1">
                    {moment(startTime, "HH:mm:ss").format("HH:mm")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    Status
                  </Typography>
                  <Typography variant="body1">
                    {scheduleStatus === "Pendente"
                      ? "Aguardando pagamento"
                      : "Pago"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    Tipo
                  </Typography>
                  <Typography variant="body1">
                    {appointmentType === "online" ? "Online" : "Presencial"}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </BoxCalendarCardInfo>
        </Modal>
      )}

      {modalConfirmPayment && (
        <Modal open>
          <BoxCalendarCardInfo>
            <IconAreaModal>
              <CloseIcon onClick={() => setModalConfirmPayment(false)} />
            </IconAreaModal>
            <Typography
              variant="p"
              sx={{
                fontWeight: "700",
                fontSize: "1.7rem",
              }}
            >
              Efetuar confirmação de pagamento?
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              width="70%"
              sx={{ mt: 10 }}
            >
              <ButtonMaterial
                sx={{ m: 1, width: 100 }}
                onClick={() => {
                  handleConfirmPayment();
                }}
                variant="contained"
              >
                Sim
              </ButtonMaterial>

              <ButtonMaterial
                sx={{ m: 1, width: 100 }}
                onClick={() => {
                  setModalConfirmPayment(false);
                }}
                color="error"
                variant="contained"
              >
                Não
              </ButtonMaterial>
            </Box>
          </BoxCalendarCardInfo>
        </Modal>
      )} */}
    </Fragment>
  );
}

export function CustomPackagesItemList({ ...props }) {
  const { data, handleEdit, handleDelete } = props;
  const { name, numberOfAppointments, price } = data;

  return (
    <CustomPackagesMain>
      <BFontBold>{name}</BFontBold>
      <MFontFinance>{`${numberOfAppointments} sessões`}</MFontFinance>
      <MFontFinance>{convertCurrencyPtBr(price)}</MFontFinance>
      <ButtonWrapper>
        <EditButton type="button" onClick={handleEdit}>
          <EditIcon active="true" />
        </EditButton>
        <DeleteButton type="button" onClick={handleDelete}>
          <DeleteIcon />
        </DeleteButton>
      </ButtonWrapper>
    </CustomPackagesMain>
  );
}

export function ProfessionalListItem({ ...props }) {
  const {
    clinic,
    data,
    deleteOwnMeetingLink,
    form,
    handleDelete,
    handleSubmitPassOn,
    setToggle,
    width,
  } = props;
  const professional = data.attributes;
  const professionalId = data.id;
  const [modalInfo, setModalInfo] = useState(false);
  const [modalPassOn, setModalPassOn] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalMeetingLink, setModalMeetingLink] = useState(false);
  const isNext =
    moment(professional?.lastSchedule?.startDate).toDate() >= moment().toDate();

  return (
    <OpenedContent>
      <PatitentImage>
        <ProfileImage
          size={width <= 480 ? "40px" : "50px"}
          src={professional.avatar}
          radius={"50%"}
        />
      </PatitentImage>
      <ProfessionalContent>
        <ProfessionalInformation>
          <ColumnItem>
            <BFontBold className="title">
              {handleUserNames(professional)}
            </BFontBold>
            <SFontDark date={"true"}>
              {professional?.lastSchedule?.startDate && isNext
                ? `${moment(
                    professional?.lastSchedule?.startDate,
                    "YYYY-MM-DD",
                  ).format("DD/MM/YYYY")} às ${moment(
                    professional?.lastSchedule?.startDate,
                    "HH:mm:ss",
                  ).format("HH:mm")}`
                : "Sem agendamentos"}
            </SFontDark>
          </ColumnItem>
          <ColumnItem>
            <SFontDark>{professional.email}</SFontDark>
            <SFontDark>{professional.phone}</SFontDark>
          </ColumnItem>
        </ProfessionalInformation>
      </ProfessionalContent>
      <RowItemPatient>
        <Tooltip title="Link Personalizado">
          <MessageButton
            backgroundColor="#fff"
            onClick={() => {
              form.setFieldValue("id", professionalId);
              setModalMeetingLink(true);
              setToggle(true);
            }}
            style={{ padding: "2.5px", marginRight: "2px" }}
          >
            <AddCircleOutlineIcon />
          </MessageButton>
        </Tooltip>

        <Tooltip title="Infomações do profissional">
          <MessageButton
            backgroundColor="#fff"
            onClick={() => setModalInfo(true)}
          >
            <InfoIcon />
          </MessageButton>
        </Tooltip>

        {clinic && professional.profession && (
          <Fragment>
            <Tooltip title="Configurar repasse">
              <DeleteButton
                onClick={() => {
                  setModalPassOn(true);
                  form.setFieldValue(
                    "newSplitPercentage",
                    professional.clinicSplitPercentage,
                  );
                }}
              >
                <PaymentIcon />
              </DeleteButton>
            </Tooltip>
            <Tooltip title="Remover profissional">
              <DeleteButton onClick={() => setModalDelete(true)}>
                <DeleteIcon />
              </DeleteButton>
            </Tooltip>
          </Fragment>
        )}
      </RowItemPatient>

      {modalInfo && (
        <Modal open>
          <BoxWrapper>
            <IconAreaModal>
              <CloseIcon onClick={() => setModalInfo(false)} />
            </IconAreaModal>
            <ModalContent>
              <ModalContent>
                <GFontBold style={{ marginBottom: "1.5rem" }}>
                  Infomações do Profissional
                </GFontBold>
                <PatitentImage style={{ marginBottom: "1rem" }}>
                  <ProfileImage
                    size="100px"
                    src={professional.avatar}
                    radius={"50%"}
                  />
                </PatitentImage>
                <BFontBold>Nome: {professional.firstName}</BFontBold>
                <BFontBold>Sobrenome: {professional.lastName}</BFontBold>
                <BFontBold>CPF: {professional.documentNumber}</BFontBold>
                <BFontBold>{professional.email}</BFontBold>
                <BFontBold>{professional.phone}</BFontBold>
              </ModalContent>
            </ModalContent>
          </BoxWrapper>
        </Modal>
      )}

      {modalPassOn && clinic && (
        <Modal open>
          <BoxWrapper>
            <IconAreaModal>
              <CloseIcon onClick={() => setModalPassOn(false)} />
            </IconAreaModal>
            <ModalContent>
              <ModalContent>
                <GFontBold style={{ marginBottom: "1.5rem" }}>
                  Infomações do Profissional
                </GFontBold>
                <BFontBold>Nome: {professional.firstName}</BFontBold>
                <BFontBold style={{ marginTop: "1rem" }}>
                  Repasse atual: {professional.clinicSplitPercentage}%
                </BFontBold>
                <div style={{ margin: "1rem 0", width: "70%" }}>
                  <FormikControl
                    control="input"
                    errors={form?.errors.newSplitPercentage}
                    label="% do Repasse '(parcela que o profissional fica de cada consulta que realizar)'"
                    name="newSplitPercentage"
                    onBlur={form?.handleBlur}
                    onChange={form?.handleChange}
                    placeholder="Digite a % do repasse"
                    touched={form?.touched.newSplitPercentage}
                    type="text"
                    upLabel
                    value={form?.values.newSplitPercentage}
                  />
                </div>
                <ButtonMaterial
                  disabled={form?.errors.newSplitPercentage}
                  type="button"
                  variant="contained"
                  onClick={() => {
                    handleSubmitPassOn({ id: data.id, values: form.values });
                    setModalPassOn(false);
                  }}
                >
                  Aplicar Repasse
                </ButtonMaterial>
              </ModalContent>
            </ModalContent>
          </BoxWrapper>
        </Modal>
      )}

      {modalDelete && clinic && (
        <Modal open>
          <BoxWrapper>
            <IconAreaModal>
              <CloseIcon onClick={() => setModalDelete(false)} />
            </IconAreaModal>
            <ModalContent>
              <ModalContent>
                <GFontBold style={{ marginBottom: "1.5rem" }}>
                  {`Deseja remover o profissional ${professional.firstName}?`}
                </GFontBold>
                <BFontBold style={{ marginBottom: "1.5rem" }}>
                  Nome: {professional.firstName}
                </BFontBold>
                <ButtonMaterial
                  type="ButtonMaterial"
                  color="error"
                  variant="contained"
                  onClick={() => {
                    handleDelete(data.id);
                    setModalDelete(false);
                  }}
                >
                  Remover
                </ButtonMaterial>
              </ModalContent>
            </ModalContent>
          </BoxWrapper>
        </Modal>
      )}

      {modalMeetingLink && (
        <Modal open>
          <BoxWrapper>
            <IconAreaModal>
              <CloseIcon onClick={() => setModalMeetingLink(false)} />
            </IconAreaModal>
            <ModalContent>
              <ModalContent>
                <GFontBold style={{ marginBottom: "1.5rem" }}>
                  {`Link Personalizado`}
                </GFontBold>
                {professional.ownMeetingLink ? (
                  <BFontBold style={{ margin: "1rem" }}>
                    {professional.ownMeetingLink}
                  </BFontBold>
                ) : (
                  <FormControlLabel
                    name="secondaryBanner"
                    control={
                      <Checkbox checked={form.values.newPreferOwnMeetingLink} />
                    }
                    label={
                      <Tooltip title="Cadastrar link personalizado">
                        <Typography>
                          Adicionar Link Personalizado{" "}
                          <span
                            style={{
                              color: theme.pink,
                              fontWeight: "bold",
                              marginLeft: "0.5rem",
                            }}
                          >
                            ?
                          </span>
                        </Typography>
                      </Tooltip>
                    }
                    onChange={({ target }) =>
                      form.setFieldValue(
                        "newPreferOwnMeetingLink",
                        target.checked,
                      )
                    }
                  />
                )}
                {form.values.newPreferOwnMeetingLink && (
                  <FormikControl
                    control="input"
                    errors={form?.errors.newOwnMeetingLink}
                    label="Link de reunião"
                    name="newOwnMeetingLink"
                    onBlur={form?.handleBlur}
                    onChange={form?.handleChange}
                    placeholder="Digite o link de reunião"
                    touched={form?.touched.newOwnMeetingLink}
                    type="text"
                    upLabel
                    value={form?.values.newOwnMeetingLink}
                  />
                )}
              </ModalContent>
              {form.values.newPreferOwnMeetingLink ? (
                <ButtonMaterial
                  disabled={form?.errors.ownMeetingLink}
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    form.handleSubmit();
                    setModalMeetingLink(false);
                  }}
                  sx={{ width: "10rem" }}
                >
                  Salvar
                </ButtonMaterial>
              ) : (
                professional.ownMeetingLink && (
                  <ButtonMaterial
                    disabled={form?.errors.ownMeetingLink}
                    type="button"
                    variant="contained"
                    color="error"
                    onClick={() => {
                      deleteOwnMeetingLink({
                        id: professionalId,
                      });
                      setModalMeetingLink(false);
                    }}
                    sx={{ width: "10rem" }}
                  >
                    Remover
                  </ButtonMaterial>
                )
              )}
            </ModalContent>
          </BoxWrapper>
        </Modal>
      )}
    </OpenedContent>
  );
}
