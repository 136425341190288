/* eslint-disable react-hooks/exhaustive-deps */
import { TextField } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FooterButtons } from "../../../components/FooterButtons";
import FormikControl from "../../../components/FormikControl";
import { Input, InputWrapper } from "../../../components/FormikElements/styles";
import { ImageCropper } from "../../../components/ImageInput";
import { FixedTextInput } from "../../../components/Input";
import LoaderSpinner from "../../../components/LoaderSpinner";
import { NewSelect } from "../../../components/SelectList";
import api from "../../../services/api";
import { PtBr } from "../../../services/pt_br";
import theme from "../../../theme";
import getProfessionName from "../../../utils/getProfessionName";
import { getProfessions } from "../../../utils/getProfessionsFilter";
import handleErrors from "../../../utils/getValuesErrors";
import { TextError } from "../../Professional/Config/styles";
import { LoginTitle } from "../../Public/Login/styles";
import {
  AddButton,
  ButtonLabel,
  ButtonRow,
  Escolher,
  Fechar,
  Label,
  LabelContainer,
  LabelInput,
  LabelWrapper,
  LoginWrapper,
  ModalContainer,
  ModalHeader,
  ModalSubTitle,
  ModalTitle,
  ModalWrapper,
  SelectButton,
  StyledModal,
} from "./styles";

const ValuesField = [
  "avatar",
  "profession",
  "job_register",
  "certificate",
  "educationCertificate",
  "formations",
  "specialities",
  "reasons",
  "approaches",
  "bio",
  "age_group",
  "experience_time",
  "appointmentTypes",
];

export function handleArray(values) {
  return values?.map(item => {
    return item.value;
  });
}

function InputCertificate({ form }) {
  const { values, setFieldValue, errors, touched } = form;

  useEffect(() => {
    setFieldValue("job_register", "");
  }, []);

  const storeImageAsBase64 = file => {
    const reader = new FileReader();

    reader.onloadend = () => {
      setFieldValue("certificate", reader.result);
      setFieldValue("certificateFileName", file.name);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div
      style={{
        fontFamily: "Poppins",
        marginBottom: "1rem",
        width: "100%",
      }}
    >
      <label htmlFor="certificate">
        <input
          id="certificate"
          name="certificate"
          onChange={e => {
            let file = e.target.files[0];
            storeImageAsBase64(file);
          }}
          type="file"
          accept="image/jpeg,image/png,application/pdf,image/x-eps"
          style={{ display: "none" }}
        />
        <AddButton variant="contained" component="span" fullWidth>
          {values.profession === "Orientador(a) Profissional"
            ? "Especialização em orientação profissional"
            : "Certificação de psicopedagogia"}
        </AddButton>
      </label>
      {values.certificateFileName && (
        <div>
          <TextField
            id="fileName"
            variant="outlined"
            value={values.certificateFileName}
            disabled={true}
            fullWidth
          />
        </div>
      )}
      <TextError>
        {errors.certificate && touched.certificate && errors.certificate}
      </TextError>
    </div>
  );
}

function InputEducationCertificate({ form }) {
  const { values, setFieldValue, errors, touched, handleBlur } = form;

  useEffect(() => {
    setFieldValue("job_register", "");
  }, []);

  const storeImageAsBase64 = file => {
    const reader = new FileReader();

    reader.onloadend = () => {
      setFieldValue("educationCertificate", reader.result);
      setFieldValue("educationCertificateFileName", file.name);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div
      style={{
        fontFamily: "Poppins",
        marginTop: "1rem",
        marginBottom: "1rem",
        width: "100%",
      }}
    >
      <label htmlFor="educationCertificate">
        <input
          id="educationCertificate"
          name="educationCertificate"
          onChange={e => {
            let file = e.target.files[0];
            storeImageAsBase64(file);
          }}
          onBlur={handleBlur}
          type="file"
          accept="image/jpeg,image/png,application/pdf,image/x-eps"
          style={{ display: "none" }}
        />
        <AddButton variant="contained" component="span" fullWidth>
          Carregar Diploma
        </AddButton>
      </label>
      {values.educationCertificateFileName && (
        <div>
          <TextField
            id="fileName"
            variant="outlined"
            value={values.educationCertificateFileName}
            disabled={true}
            fullWidth
          />
        </div>
      )}
      <TextError>
        {errors.educationCertificate &&
          touched.educationCertificate &&
          errors.educationCertificate}
      </TextError>
    </div>
  );
}

function InputNRegist({ form }) {
  const { values, handleChange, setFieldValue, handleBlur, errors, touched } =
    form;

  useEffect(() => {
    setFieldValue("certificate", "");
    setFieldValue("certificateFileName", "");
    setFieldValue("educationCertificate", "");
    setFieldValue("educationCertificateFileName", "");
  }, []);

  return (
    <Fragment>
      <FormikControl
        bgColor="#fff"
        upLabel
        labelWeight="600"
        labelSize="0.9rem"
        labelFamily="Poppins"
        control="input"
        name="job_register"
        value={values.job_register}
        onChange={handleChange}
        onBlur={handleBlur}
        label={PtBr.register_input_job_register}
      />
      <TextError>
        {errors.job_register && touched.job_register && errors.job_register}
      </TextError>
    </Fragment>
  );
}

export default function PublicProfile({ form, next, goBack }) {
  const { values, handleChange, setFieldValue, handleBlur, errors, touched } =
    form;
  const [jobs, setJobs] = useState([]);
  const [approachesModal, setApproachesModal] = useState(false);
  const [reasonsModal, setReasonsModal] = useState(false);
  const [specialitiesModal, setSpecialitiesModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formations, setFormations] = useState([]);

  const handleContinue = () => {
    if (!handleErrors(errors, ValuesField)) {
      next();
    }
  };

  const getJobsTypes = jobName => {
    const job = jobs?.find(job => job.name === jobName);
    setFieldValue(
      "jobReasons",
      job?.reasons?.length > 0 ? job?.reasons : ["N/A"],
    );
    setFieldValue(
      "jobApproaches",
      job?.approaches?.length > 0 ? job?.approaches : ["N/A"],
    );
    setFieldValue(
      "jobSpecialities",
      job?.specialities?.length > 0 ? job?.specialities : ["N/A"],
    );
  };

  useEffect(() => {
    const controller = new AbortController();
    const fetchProfessions = async () => {
      try {
        const { data } = await api.get("/professions", {
          signal: controller.signal,
        });
        const professions = getProfessions(
          data.data.attributes.professions,
        )?.map(prof => ({
          ...prof,
          name: getProfessionName(prof.name, "toPt"),
        }));

        setJobs(professions);
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error("Não foi possivel carregar profissões");
      } finally {
        setLoading(false);
      }
    };
    fetchProfessions();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    switch (values?.profession) {
      case "Professor(a)": {
        return getJobsTypes("Professor(a)");
      }
      case "Psicólogo(a)": {
        return getJobsTypes("Psicólogo(a)");
      }
      case "Orientador(a) Profissional": {
        return getJobsTypes("Orientador(a) Profissional");
      }
      case "Psicopedagogo(a)": {
        return getJobsTypes("Psicopedagogo(a)");
      }
      default: {
        return;
      }
    }
  }, [values?.profession]);

  const groups = [
    { name: "Infantil" },
    { name: "Adulto" },
    { name: "Adolescente" },
    { name: "Idoso" },
  ];

  const types = [
    { label: "Online", value: "online" },
    { label: "Presencial", value: "in_person" },
  ];

  const verifyJob = job => {
    if (["Orientador(a) Profissional"].includes(job)) {
      return (
        <Fragment>
          <InputEducationCertificate form={form} />
          <InputCertificate form={form} />
        </Fragment>
      );
    }
    if (["Orientador(a) Profissional"].includes(job)) {
      return <InputEducationCertificate form={form} />;
    }
    if (job === "Psicólogo(a)") {
      return <InputNRegist form={form} />;
    }
  };

  const deleteItemArray = (item, array, type) => {
    const newArray = array.filter(i => i !== item);
    setFieldValue(type, newArray);
  };

  return loading ? (
    <LoaderSpinner />
  ) : (
    <LoginWrapper style={{ zIndex: "0" }}>
      <InputWrapper>
        <LoginTitle>Perfil Público</LoginTitle>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            flexDirection: "column",
            marginBottom: "2rem",
            width: "100%",
          }}
        >
          <ImageCropper
            src={values?.avatar}
            name="avatar"
            onCrop={crop => setFieldValue("avatar", crop)}
          />
          <TextError>{errors.avatar}</TextError>
        </div>

        <FormikControl
          bgColor="#fff"
          id="select-profession"
          name="profession"
          label="Profissão"
          labelWeight="600"
          labelSize="0.9rem"
          labelFamily="Poppins"
          control="select"
          onChange={({ target }) => {
            setFieldValue("profession", target.value);
            setFieldValue("specialities", []);
            setFieldValue("reasons", []);
            setFieldValue("approaches", []);
          }}
          onBlur={handleBlur}
          options={jobs?.map(item => item.name)}
          placeholder={PtBr.register_input_job}
          fullWidth
        />
        <TextError>
          {errors.profession && touched.profession && errors.profession}
        </TextError>

        {verifyJob(values.profession)}

        {values.formations.length > 0 &&
          values.formations.map((f, index) => (
            <div key={f + index} style={{ width: "100%" }}>
              <FormikControl
                id="formations"
                bgColor="#fff"
                control="input"
                label={
                  index === 0 ? PtBr.register_input_formation : "Outra formação"
                }
                labelFamily="Poppins"
                labelSize="0.9rem"
                labelWeight="600"
                name={`formations[${index}]`}
                onChange={({ target }) => {
                  const newFormations = [...formations];
                  newFormations[index] = target.value;
                  setFormations(newFormations);
                }}
                onBlur={() => setFieldValue("formations", formations)}
                upLabel
                value={formations[index]}
              />
            </div>
          ))}

        <div
          style={{
            marginTop: "1.5rem",
            width: "100%",
          }}
        >
          <LabelInput
            onClick={() =>
              setFieldValue("formations", [...values.formations, ""])
            }
          >
            + Adicionar outra formação
          </LabelInput>
          <TextError>
            {errors.formations && touched.formations && errors.formations}
          </TextError>
        </div>

        {values?.jobSpecialities?.length > 0 && values?.profession && (
          <div
            style={{
              marginTop: "0.5rem",
              width: "100%",
            }}
          >
            <LabelInput>{PtBr.register_input_specialty}</LabelInput>
            <SelectButton
              id="specialities-button"
              type="button"
              disabled={values.profession ? false : true}
              onClick={() => setSpecialitiesModal(true)}
            >
              {PtBr.register_button_select}
            </SelectButton>
            {values?.specialities?.length > 0 && (
              <LabelContainer>
                {values?.specialities?.map((item, index) => (
                  <LabelWrapper>
                    <Label key={item + index}>{item}</Label>
                    <ButtonLabel
                      type="button"
                      onClick={() =>
                        deleteItemArray(
                          item,
                          values?.specialities,
                          "specialities",
                        )
                      }
                    >
                      X
                    </ButtonLabel>
                  </LabelWrapper>
                ))}
              </LabelContainer>
            )}
            <TextError>
              {errors.specialities &&
                touched.specialities &&
                errors.specialities}
            </TextError>
          </div>
        )}

        {values?.jobReasons?.length > 0 && values?.profession && (
          <div
            style={{
              marginTop: "1.5rem",
              width: "100%",
            }}
          >
            <LabelInput>
              {values.profession === "Professor(a)"
                ? "Matérias"
                : PtBr.register_input_reasons}
            </LabelInput>
            <SelectButton
              id="reasons-button"
              type="button"
              onClick={() => setReasonsModal(true)}
            >
              {PtBr.register_button_select}
            </SelectButton>
            {values?.reasons?.length > 0 && (
              <LabelContainer>
                {values?.reasons?.map((item, index) => (
                  <LabelWrapper>
                    <Label key={item + index}>{item}</Label>
                    <ButtonLabel
                      type="button"
                      onClick={() =>
                        deleteItemArray(item, values?.reasons, "reasons")
                      }
                    >
                      X
                    </ButtonLabel>
                  </LabelWrapper>
                ))}
              </LabelContainer>
            )}
            <TextError>
              {errors.reasons && touched.reasons && errors.reasons}
            </TextError>
          </div>
        )}

        {values?.jobApproaches?.length > 0 &&
          ["Psicólogo(a)", "Orientador(a) Profissional"].includes(
            values?.profession,
          ) && (
            <div
              style={{
                marginTop: "1.5rem",
                width: "100%",
              }}
            >
              <LabelInput>{PtBr.register_input_approaches}</LabelInput>
              <SelectButton
                id="approaches-button"
                type="button"
                disabled={values.profession ? false : true}
                onClick={() => setApproachesModal(true)}
              >
                {PtBr.register_button_select}
              </SelectButton>
              {values?.approaches?.length > 0 && (
                <LabelContainer>
                  {values?.approaches?.map((item, index) => (
                    <LabelWrapper>
                      <Label key={item + index}>{item}</Label>
                      <ButtonLabel
                        type="button"
                        onClick={() =>
                          deleteItemArray(
                            item,
                            values?.approaches,
                            "approaches",
                          )
                        }
                      >
                        X
                      </ButtonLabel>
                    </LabelWrapper>
                  ))}
                </LabelContainer>
              )}
              <TextError>
                {errors.approaches && touched.approaches && errors.approaches}
              </TextError>
            </div>
          )}

        <LabelInput style={{ marginTop: "1rem" }}>
          {PtBr.register_input_bio}
        </LabelInput>
        <FormikControl
          bgColor={theme.white}
          control="textarea"
          height="5rem"
          name="bio"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={PtBr.register_input_about_you}
          value={values.bio}
        />
        <TextError>{errors.bio && touched.bio && errors.bio}</TextError>

        <NewSelect
          bgColor={theme.white}
          placeholder={PtBr.register_input_age_group}
          label={PtBr.register_input_age_group}
          name="age_group"
          isMulti={true}
          onChange={e => {
            setFieldValue(
              "age_group",
              e?.map(age => `${age.value}`),
            );
          }}
          options={groups?.map(item => ({
            label: item.name,
            value: item.name,
          }))}
        />
        <TextError>
          {errors.age_group && touched.age_group && errors.age_group}
        </TextError>

        <FixedTextInput
          type="number"
          name="experience_time"
          value={values.experience_time}
          onChange={handleChange}
          onBlur={handleBlur}
          label={PtBr.register_input_experience_time}
          min={0}
        />
        <TextError>
          {errors.experience_time &&
            touched.experience_time &&
            errors.experience_time}
        </TextError>

        {!values.worksOnline && (
          <Fragment>
            <NewSelect
              bgColor={theme.white}
              placeholder={PtBr.register_input_session_type}
              label={PtBr.register_input_session_type}
              name="appointmentTypes"
              isMulti={true}
              onChange={e => setFieldValue("appointmentTypes", handleArray(e))}
              options={types}
            />
            <TextError>
              {errors.session_type &&
                touched.session_type &&
                errors.session_type}
            </TextError>
          </Fragment>
        )}

        <Input
          type="url"
          name="presentationVideo"
          value={values.presentationVideo}
          onChange={handleChange}
          onBlur={handleBlur}
          label={PtBr.register_input_presetation_video}
          placeholder={"https://www.youtube.com/"}
        />
        <TextError>{errors.presentationVideo}</TextError>

        <FooterButtons
          goBack={goBack}
          next={() => handleContinue()}
          warning={true}
        />
      </InputWrapper>

      <StyledModal
        ariaHideApp={false}
        isOpen={specialitiesModal}
        onRequestClose={() => setSpecialitiesModal(false)}
        onAfterOpen={null}
      >
        <ModalContainer>
          <ModalWrapper>
            <ModalHeader>
              <ModalTitle>Especialidade(s)</ModalTitle>
              <ModalSubTitle>
                {values?.profession === "Professor(a)"
                  ? "Selecione até 2"
                  : "Selecione até 8"}
              </ModalSubTitle>
            </ModalHeader>

            <FormikControl
              name="specialities"
              control="checkbox-group"
              options={values?.jobSpecialities?.map(item => {
                return { key: item, value: item };
              })}
              disabled={values?.specialities?.length >= 8 ? true : false}
            />

            <ButtonRow>
              <Fechar
                title="Cancelar"
                onClick={() => setSpecialitiesModal(false)}
              />
              <Escolher
                id="specialities-confirm"
                title="Confirmar"
                onClick={() => setSpecialitiesModal(false)}
              />
            </ButtonRow>
          </ModalWrapper>
        </ModalContainer>
      </StyledModal>

      <StyledModal
        ariaHideApp={false}
        isOpen={reasonsModal}
        onRequestClose={() => setReasonsModal(false)}
        onAfterOpen={null}
      >
        <ModalContainer>
          <ModalWrapper>
            <ModalHeader>
              <ModalTitle>
                {values?.profession === "(Professor(a))"
                  ? "Matérias"
                  : PtBr.register_input_reasons}
              </ModalTitle>
              <ModalSubTitle>Selecione até 8</ModalSubTitle>
            </ModalHeader>

            <FormikControl
              name="reasons"
              control="checkbox-group"
              options={values?.jobReasons?.map(item => {
                return { key: item, value: item };
              })}
              disabled={values?.reasons?.length >= 8 ? true : false}
            />

            <ButtonRow>
              <Fechar title="Cancelar" onClick={() => setReasonsModal(false)} />
              <Escolher
                id="reasons-confirm"
                title="Confirmar"
                onClick={() => setReasonsModal(false)}
              />
            </ButtonRow>
          </ModalWrapper>
        </ModalContainer>
      </StyledModal>

      <StyledModal
        ariaHideApp={false}
        isOpen={approachesModal}
        onRequestClose={() => setApproachesModal(false)}
        onAfterOpen={null}
      >
        <ModalContainer>
          <ModalWrapper>
            <ModalHeader>
              <ModalTitle>{PtBr.register_input_approaches}</ModalTitle>
              <ModalSubTitle>Selecione até 8</ModalSubTitle>
            </ModalHeader>

            <FormikControl
              name="approaches"
              control="checkbox-group"
              options={values?.jobApproaches?.map(item => {
                return { key: item, value: item };
              })}
              disabled={values?.approaches?.length >= 8 ? true : false}
            />

            <ButtonRow>
              <Fechar
                title="Cancelar"
                onClick={() => setApproachesModal(false)}
              />
              <Escolher
                id="approaches-confirm"
                title="Confirmar"
                onClick={() => setApproachesModal(false)}
              />
            </ButtonRow>
          </ModalWrapper>
        </ModalContainer>
      </StyledModal>
    </LoginWrapper>
  );
}
